<template>
  <button class="button" :class="[type]" @click="click"></button>
</template>

<script>
export default {
  name: "btn1",
  props: {
    type: String,
  },
  methods: {
    click(e) {
      this.$emit("click", e);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@use "@/css/utility/_colors" as colors;
@use "@/css/utility/_mixin" as mixin;
@use "@/css/utility/_media" as media;
@use "@/css/utility/_font" as font;
@use "@/css/utility/_variables" as variables;
.button {
  width: 73px;
  height: 34px;
  border-radius: 21px;
  background: {
    color: #e5f9f9;
    position: center;
    repeat: no-repeat;
    size: 18px auto;
  }
  @media screen and (min-width: 768px) and (max-width: 1400px) {
    width: calc(73 / 1440 * 100vw);
    height: calc(34 / 1440 * 100vw);
    background-size: calc(18 / 1440 * 80vw) auto;
  }
}

.edit {
  background: {
    image: url(../../../assets/icon-edit-1.svg);
  }
}

.preview {
  background: {
    image: url(../../../assets/icon-preview-1.svg);
  }
}

.mail {
  background: {
    image: url(../../../assets/icon-mail-2.svg);
  }
}
</style>

<template lang="pug">
  main.login
    #title-area
      .logo
        img(src="@/assets/logo-horizontal-1.svg")
      span.version 管理者用画面 version 1.0.0
    .login-form
      input(type="text" v-model="loginId" placeholder="ID")
      input(type="password" v-model="password" placeholder="パスワード")
      span {{ errorMessage }}

      btn(
        class="submit"
        btnTxt="ログイン"
        color="primary"
        size="s"
        @click="executeLogin"
      )

      span(
        @click="showForgotPass = !showForgotPass"
        class="forgotPass"
      ) パスワードを忘れた場合

      transition(name="fade")
        .forgotPassDialog(v-show="showForgotPass")
          .forgotPassDialog-blk info@sample.com までご連絡ください。
            span(class="forgotPassDialog-close" @click="showForgotPass = !showForgotPass") ×
</template>

<style scoped lang="scss">
@use "@/css/utility/_colors" as colors;
@use "@/css/utility/_mixin" as mixin;
@use "@/css/utility/_media" as media;
@use "@/css/utility/_font" as font;
@use "@/css/utility/_variables" as variables;
#title-area {
  display: flex;
  flex-direction: column;
  align-items: center;
  @include media.max-screen(media.$breakpoint-sm) {
    padding: 40px 0 80px;
  }
  @include media.min-screen(media.$breakpoint-md) {
    padding: 100px 0 80px;
  }
}
.logo {
  width: 230px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  img {
    width: 100%;
  }
}
.version {
  font-weight: bold;
  font-size: 15px;
  letter-spacing: 0.06em;
  line-height: 34px;
  text-align: center;
  color: #3b4043;
}
.login-form {
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 250px;
  margin-left: auto;
  margin-right: auto;
}
input[type="text"],
input[type="password"] {
  width: 250px;
  height: 40px;
  border-radius: 3px;
  background: linear-gradient(#f4f4f4 0%, #ededed 100%);
  border: 1px solid #ededed;
  box-sizing: border-box;
  padding: 0 15px;
  margin-bottom: 33px;
}
.submit {
  margin-top: 12px;
  margin-bottom: 65px;
}
.forgotPass {
  @extend .f_caption;
  text-decoration: underline;
  margin-left: auto;
  cursor: pointer;
}
.forgotPassDialog {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  left: 0;
}
.forgotPassDialog-blk {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 250px;
  height: 400px;
  background: #fff;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 30px;
  @extend .f_body;
}
.forgotPassDialog-close {
  width: 50px;
  height: 50px;
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  border-radius: 50%;
  border: solid 1px #cbcbcb;
  background: #000;
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>

<script>
import btn from "@/components/admin/common/btn-1";
import ApiClient from "@/lib/http.js";
export default {
  name: "login",
  metaInfo() {
    return {
      title: "ログイン | イベント備品レンタル倉庫 カリトク",
      meta: [
        { name: "description", content: "イベント備品レンタル倉庫 カリトク" },
        { name: "keywords", content: "" },
      ],
    };
  },
  components: {
    btn,
  },
  data: function () {
    return {
      showForgotPass: false,
      loginId: "",
      password: "",
      errorMessage: "",
    };
  },
  methods: {
    async executeLogin() {
      let param = {
        login_id: this.loginId,
        password: this.password,
      };
      const api = new ApiClient();
      let data = await api.post("/admin/login", param, false);
      if (data.state == 204) {
        this.errorMessage = data.errors.loginid[0];
        return;
      }
      this.$router.push("/admin/home");
    },
  },
};
</script>

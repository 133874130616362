<template lang="pug">
  .about
    main.user
      Header
      section.kv
      section.content
        .m-content
          .breadcrumb
            breadcrumb(
              h2txt="レンタル商品の受渡しと組み立てについて"
              h2link=""
              h3txt=""
              h3link=""
            )

          section
            h2.head
              head2(ja="レンタル商品の受渡しと<br class='m-sm'>組み立てについて" en="Delivery")
              .head-img
                img(src="@/assets/delivery-img.png")

            section.topic
              ul.topic-list
                li.topic-list__item
                  h3.item-head レンタル期間について
                  p.item-desc レンタル期間については、お引き取りの場合はお引取り日、配送の場合はお客さまが商品をお受け取りになったお日にちをレンタル開始日とし、商品の引き渡し日を返却日としています。
                    br
                    |開始日から返却日がレンタル期間となりますが、休日などをはさむ場合、イベント開催日数とレンタル日とが異なるような場合はのレンタル期間につきましては、一律にこの限りではありません。別途お見積りを致しますので、お気軽にお電話またはメールにてお問い合わせ下さい。
                li.topic-list__item#delivery
                  h3.item-head 受け渡し方法について
                  p.item-desc.hasMargin 受け渡し方法は、商品の往路・復路ともに郵送、配送、お引取りからお選びいただけます。
                    span.small ※郵送は一部の地域ではお届けできない場合がございます。

                  .item-illust
                    span.item-label 配送の場合

                    ul.list
                      li.list-item
                        .list-img
                          img(src="@/assets/deliver-img-1-1.png")
                        span.list-head 商品のお受け取り

                      li.list-item
                        .list-img
                          img(src="@/assets/deliver-img-1-2.png")
                        span.list-head イベント当日

                      li.list-item
                        .list-img
                          img(src="@/assets/deliver-img-1-3.png")
                        span.list-head 当社スタッフが引き上げ

                    .item-illust
                      span.item-label 郵送の場合

                    ul.list
                      li.list-item
                        .list-img
                          img(src="@/assets/deliver-img-2-1.png")
                        span.list-head 郵送でお受け取り

                      li.list-item
                        .list-img
                          img(src="@/assets/deliver-img-2-2.png")
                        span.list-head イベント当日

                      li.list-item
                        .list-img
                          img(src="@/assets/deliver-img-2-3.png")
                        span.list-head 郵送でご返却

                  .item-illust
                    span.item-label お引取りの場合

                    ul.list
                      li.list-item
                        .list-img
                          img(src="@/assets/deliver-img-3-1.png")
                        span.list-head ご来店でお受け取り

                      li.list-item
                        .list-img
                          img(src="@/assets/deliver-img-3-2.png")
                        span.list-head イベント当日

                      li.list-item
                        .list-img
                          img(src="@/assets/deliver-img-3-3.png")
                        span.list-head ご来店でご返却

                    .note
                      span.head ※ 引き取り場所…
                      a(href="https://www.google.com/maps/place/%E3%80%92557-0061+%E5%A4%A7%E9%98%AA%E5%BA%9C%E5%A4%A7%E9%98%AA%E5%B8%82%E8%A5%BF%E6%88%90%E5%8C%BA%E5%8C%97%E6%B4%A5%E5%AE%88%EF%BC%91%E4%B8%81%E7%9B%AE%EF%BC%98%E2%88%92%EF%BC%98/@34.6555104,135.4831436,17z/data=!3m1!4b1!4m5!3m4!1s0x6000e79edae5b6b7:0x56f7b086e21d808d!8m2!3d34.6555104!4d135.4831436" target="blank") 大阪市西成区北津守 1-8-8
                      span.icon
                        img(src="@/assets/icon-map.svg")



            section.topic
              ul.topic-list
                li.topic-list__item
                  h3.item-head.q 組み立てサポートとは何ですか？
                  .a
                    p.item-desc 組み立てサポートとは、特に大型のレンタル用などをお届けまたは引き上げ時に、お客さまに代わって弊社の担当スタッフが
                      br.m-md
                      |組み立てや解体作業をサポートさせて頂くサービスです。
                      br
                      |作業代金は、作業時間やお品物によって異なりますので、別途お見積りとなります。お気軽にご相談下さい。

                    .item-illust

                      ul.list.noPadding
                        li.list-item
                          .list-img
                            img(src="@/assets/deliver-img-4-1.png")
                          span.list-head 商品のお受け取り

                        li.list-item
                          .list-img
                            img(src="@/assets/deliver-img-4-2.png")
                          span.list-head 組み立てサポート

                        li.list-item
                          .list-img
                            img(src="@/assets/deliver-img-4-3.png")
                          span.list-head イベント当日

                        li.list-item
                          .list-img
                            img(src="@/assets/deliver-img-4-4.png")
                          span.list-head 組み立てサポート

              ul.topic-list
                li.topic-list__item
                  h3.item-head.q 配送や郵送の料金はいくらになりますか？
                  .a
                    p.item-desc お届けの方法、お届け先、お荷物のサイズによって料金が異なります。※郵送は一部の地域ではお届けできない場合がございます。
                      br
                      |詳しくは、別途お見積りとなりますので、お電話にて担当スタッフにご相談下さい。




        section.bnrs-blk
          //- バナー
          bnrs(seeing="faq")

        contact
        Footer
</template>

<script>
// @ is an alias to /src
import Header from "@/components/user/common/header.vue";
import Footer from "@/components/user/common/footer.vue";
import contact from "@/components/user/common/contact.vue";
import breadcrumb from "@/components/common/breadcrumb.vue";
import head2 from "@/components/common/head-2.vue";
import bnrs from "@/components/user/common/bnrs.vue";
export default {
  name: "About",
  metaInfo: {
    title:
      "レンタル商品の受渡しと組み立てについて | イベント備品レンタル倉庫 カリトク",
    meta: [
      { name: "description", content: "イベント備品レンタル倉庫 カリトク" },
      { name: "keywords", content: "" },
    ],
  },
  components: {
    Header,
    contact,
    Footer,
    breadcrumb,
    head2,
    bnrs,
  },
};
</script>
<style lang="scss" scoped>
@use "@/css/utility/_colors" as colors;
@use "@/css/utility/_mixin" as mixin;
@use "@/css/utility/_media" as media;
@use "@/css/utility/_font" as font;
@use "@/css/utility/_variables" as variables;
.kv {
  width: 100%;
  @include mixin.liquidSize(height, 193, 188);
  background: {
    image: url(../assets/bg-faq.jpg);
    position: center;
    size: cover;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

.head {
  @include mixin.liquidSize(margin-bottom, 80, 0);
  position: relative;
}

.head-img {
  @include mixin.liquidSize(width, 187, 187);
  @include mixin.liquidSize(height, 170, 170);
  @include media.max-screen(media.$breakpoint-sm) {
    margin-left: auto;
  }
  @include media.min-screen(media.$breakpoint-md) {
    position: absolute;
    top: -30px;
    right: 68px;
  }
}

//
.topic-list__item {
  @include mixin.liquidSize(padding-top, 100, 100);
  .item-head {
    border-left: solid 1px #74bdcb;
    background: #f7f9f9;
    @include mixin.liquidSize(height, 61, 80);
    box-sizing: border-box;
    @include mixin.liquidSize(padding-left, 25, 30);
    @include mixin.liquidSize(margin-bottom, 27, 50);
    @include mixin.liquidSize(font-size, 18, 28);
    font-weight: 700; /* bold */
    display: flex;
    align-items: center;
    &.q {
      border-left: none;
      &:before {
        content: "Q";
        color: #74bdcb;
        @include mixin.liquidSize(font-size, 32, 40);
        @include mixin.liquidSize(margin-right, 18, 20);
      }
    }
  }
  .a {
    .item-desc {
      @include mixin.liquidSize(margin-bottom, 30, 20);
    }
  }
  .item-desc {
    box-sizing: border-box;
    @include mixin.liquidSize(padding-left, 25, 30);
    line-height: 1.8;
    @include mixin.liquidSize(font-size, 15, 24);
    .small {
      @include mixin.liquidSize(font-size, 13, 20);
    }
    //例外
    &.hasMargin {
      @include mixin.liquidSize(margin-bottom, 50, 60);
    }
  }
}

//
.item-illust {
  box-sizing: border-box;
  @include mixin.liquidSize(padding-left, 25, 30);

  .item-label {
    @include mixin.liquidSize(font-size, 17, 26);
    font-weight: 700; /* bold */
    color: #1890a7;
    background: #fff;
    border: solid 1px #1890a7;
    @include mixin.liquidSize(border-radius, 22, 40);
    @include mixin.liquidSize(width, 176, 300);
    @include mixin.liquidSize(height, 43, 60);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .note {
    @include mixin.liquidSize(font-size, 15, 20);
    .head {
      @include mixin.liquidSize(font-size, 15, 20);
      @include mixin.liquidSize(margin-right, 0, 0);
    }
    a {
      color: #1890a7;
      border-bottom: dotted 1px #1890a7;
    }
    .icon {
      @include mixin.liquidSize(width, 18, 29);
      @include mixin.liquidSize(height, 20, 32);
      @include mixin.liquidSize(margin-left, 6, 12);
      display: inline-block;
      img {
        width: 100%;
      }
    }
  }
}

//
.list {
  display: flex;
  flex-wrap: wrap;
  @include mixin.liquidSize(padding-bottom, 85, 0);
  &.noPadding {
    @include mixin.liquidSize(padding-bottom, 0, 0);
  }
}

.list-item {
  @include mixin.liquidSize(width, 250, 330);
  @include mixin.liquidSize(margin-left, 25, 0);
  @include mixin.liquidSize(margin-right, 25, 0);
  @include mixin.liquidSize(margin-bottom, 0, 100);
  position: relative;
  @include media.max-screen(media.$breakpoint-sm) {
    &:nth-of-type(odd) {
      margin-right: auto;
    }
    &:nth-of-type(even) {
      .list-img {
        &:after {
          display: none;
        }
      }
    }
  }

  &:not(:last-of-type) {
    .list-img {
      &:after {
        content: "";
        @include mixin.liquidSize(width, 27, 24);
        @include mixin.liquidSize(height, 27, 24);
        position: absolute;
        @include mixin.liquidSize(right, -10, -4);
        top: 50%;
        transform: translate(100%, -50%);
        background: {
          image: url(../assets/icon-arrow-3.svg);
          size: contain;
          repeat: no-repeat;
        }
      }
    }
  }
}

.list-img {
  @include mixin.liquidSize(height, 224, 224);
  position: relative;
  img {
    max-width: none;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: auto;
    height: 100%;
  }
}

.list-head {
  display: block;
  color: #50aabb;
  font-weight: 700; /* bold */
  @include mixin.liquidSize(font-size, 18, 25);
  text-align: center;
  @include mixin.lhFix(1);
}

.list-desc {
  font-weight: 500; /* medium */
  @include mixin.liquidSize(font-size, 15, 24);
  @include mixin.lhFix(2);
  line-height: 2;
  box-sizing: border-box;
  color: #3e3e3e;
  .hasBorder {
    text-decoration: underline;
  }
  .link {
    color: #50aabb;
    display: inline-block;
    @include mixin.liquidSize(margin-top, 10, 10);
    .icon {
      position: relative;
      @include mixin.liquidSize(top, 8, 12);
      @include mixin.liquidSize(width, 17, 28);
      @include mixin.liquidSize(height, 16, 28);
      display: inline-block;
      @include mixin.liquidSize(margin-right, 6, 6);
    }
    .txt {
      @include mixin.liquidSize(font-size, 15, 24);
    }
  }
}

.bnrs-blk {
  @include mixin.liquidSize(margin-top, 140, 100);
  @include mixin.liquidSize(margin-bottom, 60, 100);
}
</style>

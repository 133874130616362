<!-- 日英 横並び -->
<template lang="pug">
  ul.cart-list
    li(v-for="item,index in this.items" :key="`item${index}`")
      .gr-1
        span.type {{item.size}}
        span.price {{item.price.toLocaleString()}}円(税込)

      .btn
        .minus(@click="minus(item)")
        .num {{ item.amount }}
        .plus(@click="plus(item)")

</template>

<script>
export default {
  name: "cartItem",

  data: function () {
    return {
      amount: 0, //数
      total: 0, //この商品の小計
    };
  },
  props: {
    items: [],
  },
  methods: {
    plus: function (item) {
      item.amount++;
      this.calcTotal();
    },
    minus: function (item) {
      item.amount--;
      if (item.amount < 0) {
        item.amount = 0;
      }
      this.calcTotal();
    },
    calcTotal: function () {
      this.total = 0;
      for (let i = 0; i < this.items.length; i++) {
        let o = this.items[i];
        this.total += o.price * o.amount;
      }
      this.$emit("changeTotal", this.total, this.items);
    },
  },
  watch: {
    items: function () {
      this.calcTotal();
    },
  },
};
</script>

<style scoped lang="scss">
@use "@/css/utility/_colors" as colors;
@use "@/css/utility/_mixin" as mixin;
@use "@/css/utility/_media" as media;
@use "@/css/utility/_font" as font;
@use "@/css/utility/_variables" as variables;
.cart-list {
  @include mixin.liquidSize(margin-bottom, 20, 60);
}
li {
  border-bottom: dotted 1px #dedede;
  @include mixin.liquidSize(padding-top, 13, 30);
  @include mixin.liquidSize(padding-bottom, 13, 30);
  @include mixin.liquidSize(padding-left, 0, 20);
  display: flex;
  align-items: center;
  box-sizing: border-box;
}
.gr-1 {
  @include mixin.liquidSize(width, 425, 480);
  box-sizing: border-box;
  @include mixin.liquidSize(padding-right, 30, 60);
}
.type {
  @include mixin.liquidSize(font-size, 17, 20);
  @include mixin.liquidSize(margin-right, 25, 0);
  @include media.max-screen(media.$breakpoint-sm) {
    display: block;
  }
}
.price {
  color: #1890a7;
  @include mixin.liquidSize(font-size, 17, 26);
}
.btn {
  display: flex;
  align-items: center;
  @include mixin.liquidSize(width, 144, 300);
  @include mixin.liquidSize(height, 44, 80);
  @include mixin.liquidSize(border-radius, 33, 40);
  border: solid 1px #848484;
  .num {
    box-sizing: border-box;
    border-left: dotted 1px #dedede;
    border-right: dotted 1px #dedede;
    @include mixin.liquidSize(font-size, 17, 30);
    color: #848484;
    @include mixin.liquidSize(width, 54, 100);
    @include mixin.liquidSize(height, 33, 42);
    @include mixin.liquidSize(padding-right, 15, 18);
    @include mixin.liquidSize(padding-top, 5, 0);
    @include mixin.liquidSize(padding-bottom, 0, 5);
    text-align: right;
  }
  .plus,
  .minus {
    @include mixin.liquidSize(width, 45, 100);
    height: 100%;
    position: relative;
    &:before,
    &:after {
      content: "";
      background: #707070;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .plus {
    &:before,
    &:after {
      left: 40%;
    }
    &:before {
      @include mixin.liquidSize(width, 16, 30);
      height: 1px;
    }
    &:after {
      @include mixin.liquidSize(height, 16, 30);
      width: 1px;
    }
  }
  .minus {
    &:before {
      left: 60%;
      @include mixin.liquidSize(width, 16, 30);
      height: 1px;
    }
  }
}
</style>

<template lang="pug">
  #cart
    #cart-product
      .cart-head
        span.name 製品名
        span.price.m-md 値段
        span.amount 個数
        span.support 組み立て
          br
          |サポート
          span.icon
            img(src="@/assets/icon-q.svg")
      cartItem(
        @changeTotal="total"
        @update="update"
        @deleteItem="deleteItem"
        :items="cartItem"
      )
      //-.error(v-if="error.carts") {{ error.carts[0]}}

      p.note
        span.icon
          img(src="@/assets/icon-q.svg")
        span.head 組み立てサポート：
        |組み立て・解体作業を弊社でサポートするサービスです。

      .period
        span.head レンタル期間：
        //- TODO datepicker に差し替える
        .gr
          input(type="date" name="startDate" v-model="model.start_date" @change="calcPeriod")
          span.txt 開始
        .gr
          input(type="date" name="endDate" v-model="model.end_date" @change="calcPeriod")
          span.txt 終了
        //-.error(v-if="error.start_date") {{ error.start_date[0]}}
        //-.error(v-if="error.end_date") {{ error.end_date[0]}}
        //-.error(v-if="error.holiday") {{ error.holiday[0]}}
        //-.error(v-if="error.businessDay") {{ error.businessDay[0]}}


    #cart-price
      .subtotal.item
        span.head 泊数
        span.num {{period.toLocaleString()}}
        span.currency 泊
      .subtotal.item
        span.head 小計
        span.num {{subtotal.toLocaleString()}}
        span.currency 円
      .tax.item
        span.head 消費税
        span.num {{tax.toLocaleString()}}
        span.currency 円
      .total
        span.head 合計
        span.num {{fixPrice.toLocaleString()}}
        span.currency 円


</template>

<script>
import cartItem from "@/components/user/cart/cartItem.vue";
export default {
  name: "cart",
  components: {
    cartItem,
  },
  data: function () {
    return {
      subtotal: 0,
      tax: 0,
      totalPrice: 0, //cartItemからの合計金額（1日分の金額）
      after_second_day_price: 0, //二日以降の価格
      items: [],
      period: 0,
      fixPrice: 0, //レンタル期間を考慮した金額（totalPrice * period）
      error: {},
    };
  },
  props: {
    cartItem: [],
    model: {},
    errors: {},
  },
  model: {
    prop: "model",
    event: "change",
  },
  methods: {
    total(...args) {
      this.totalPrice = args[0];
      this.items = args[1];
      this.after_second_day_price = args[2];
      this.calcFixPrice();
    },
    calcPeriod(e) {
      let st = new Date(this.model.start_date);
      let en = new Date(this.model.end_date);
      let today = new Date();
      let period = (en - st) / 86400000;
      let leftDays = (st - today) / 86400000;

      if (period <= 0) {
        console.log("0日かマイナス");
        console.log(e);
        e.target.value = "";
        this.error.end_date = "終了日は開始日以降に設定してください";
        this.$emit("periodError");
        return;
      }

      isNaN(period) ? (this.period = 0) : (this.period = period);
      this.calcFixPrice();
      this.$emit("changePeriod", leftDays);
    },
    calcFixPrice() {
      if (isNaN(this.period) || this.period <= 0) {
        return;
      }
      //this.fixPrice = this.totalPrice * this.period;
      //1,2泊の価格 = this.totalPrice
      //3泊目以降の価格
      let after_second_day_price;
      if (this.period > 2) {
        after_second_day_price =
          (this.period - 2) * this.after_second_day_price;
      } else {
        after_second_day_price = 0;
      }

      this.fixPrice = this.totalPrice + after_second_day_price;

      this.subtotal = Math.ceil(this.fixPrice / 1.1);
      this.tax = Math.ceil(this.fixPrice - this.subtotal);
      this.model.fix_price = this.fixPrice;
      this.model.subtotal = this.subtotal;
      this.model.tax = this.tax;
      //TODO 確か何日以上でn%割引みたいなのがあった気がする
    },
    update(item) {
      this.$emit("update", item);
    },
    deleteItem: function (id) {
      this.$emit("deleteItem", id);
    },
  },
  watch: {
    errors: function (newError) {
      this.error = newError;
    },
    cartItem: function () {
      this.calcPeriod();
    },
  },
};
</script>

<style scoped lang="scss">
@use "@/css/utility/_colors" as colors;
@use "@/css/utility/_mixin" as mixin;
@use "@/css/utility/_media" as media;
@use "@/css/utility/_font" as font;
@use "@/css/utility/_variables" as variables;
#cart {
  @include mixin.liquidSize(margin-bottom, 45, 60);
  @include media.min-screen(media.$breakpoint-md) {
    display: flex;
    align-items: flex-start;
  }
}

#cart-product {
  @include mixin.liquidSize(width, 954, 700);
  background: #fff;
  box-sizing: border-box;
  @include mixin.liquidSize(padding, 55, 40);
}

.cart-head {
  display: flex;
  @include mixin.liquidSize(font-size, 14, 20);
  .name {
    @include mixin.liquidSize(width, 480, 316);
  }
  .price {
    @include mixin.liquidSize(width, 143, 0);
  }
  .amount {
    @include mixin.liquidSize(width, 144, 224);
  }
  .support {
    @include mixin.liquidSize(width, 77, 80);
    text-align: right;
    white-space: nowrap;
    position: relative;
    .icon {
      @include mixin.liquidSize(width, 22, 22);
      @include mixin.liquidSize(height, 22, 22);
      position: absolute;
      @include mixin.liquidSize(right, -10, -4);
      @include mixin.liquidSize(top, 10, 4);
      transform: translate(100%, 0);
    }
  }
}

.note {
  color: #6f6f6f;
  @include mixin.liquidSize(font-size, 13, 20);
  box-sizing: border-box;
  @include mixin.liquidSize(padding-left, 32, 32);
  line-height: 1.5;
  position: relative;
  border-bottom: dotted 1px #dedede;
  @include mixin.liquidSize(padding-bottom, 20, 20);
  .icon {
    @include mixin.liquidSize(width, 22, 22);
    @include mixin.liquidSize(height, 22, 22);
    position: absolute;
    left: 0;
    @include mixin.liquidSize(top, -1, 5);
  }
  .head {
    color: #000;
  }
}

.period {
  @include mixin.liquidSize(font-size, 15, 26);
  @include mixin.liquidSize(margin-top, 30, 30);
  display: flex;
  @include media.max-screen(media.$breakpoint-sm) {
    flex-direction: column;
  }
  &:before {
    content: "必須";
    background: #e57e7e;
    color: #fff;
    white-space: nowrap;
    @include mixin.liquidSize(font-size, 13, 26);
    @include mixin.liquidSize(width, 41, 80);
    @include mixin.liquidSize(height, 20, 40);
    @include mixin.liquidSize(margin-right, 12, 0);
    @include mixin.liquidSize(margin-bottom, 0, 20);
    @include mixin.liquidSize(border-radius, 4, 8);

    box-sizing: border-box;
    @include mixin.liquidSize(padding-left, 6, 12);
    @include mixin.liquidSize(padding-right, 6, 12);
    @include media.max-screen(media.$breakpoint-sm) {
      display: block;
    }
    @include media.min-screen(media.$breakpoint-md) {
      display: inline-block;
    }
  }
  .head {
    @include mixin.liquidSize(margin-bottom, 0, 20);
    @include mixin.liquidSize(margin-right, 10, 20);
  }
  .gr {
    display: flex;
    align-items: center;
    @include mixin.liquidSize(margin-bottom, 0, 30);
    .txt {
      color: #6f6f6f;
      @include mixin.liquidSize(margin-right, 20, 0);
      position: relative;
      @include mixin.liquidSize(top, -7, -4);
    }
  }
  input {
    @include mixin.liquidSize(width, 142, 520);
    @include mixin.liquidSize(height, 30, 80);
    @include mixin.liquidSize(padding-bottom, 15, 0);
    @include mixin.liquidSize(margin-right, 15, 20);
    border-bottom: dotted 1px #dedede;
    box-sizing: border-box;
    @include mixin.liquidSize(font-size, 15, 26);
  }
}

#cart-price {
  @include mixin.liquidSize(width, 350, 700);
  background: #fff;
  box-sizing: border-box;
  @include mixin.liquidSize(padding-top, 45, 70);
  @include mixin.liquidSize(padding-bottom, 30, 60);
  @include mixin.liquidSize(padding-left, 25, 40);
  @include mixin.liquidSize(padding-right, 25, 40);
  @include media.min-screen(media.$breakpoint-md) {
    margin-left: auto;
  }
}

#cart-price {
  //小計、消費税
  .item {
    display: flex;
    align-items: center;
    &:not(:last-of-type) {
      @include mixin.liquidSize(margin-bottom, 5, 6);
    }
    .head {
      color: #939393;
      @include mixin.liquidSize(font-size, 18, 27);
      font-weight: 500; /* medium */
    }
    .num {
      color: #1890a7;
      margin-left: auto;
      @include mixin.liquidSize(font-size, 26, 32);
      @include mixin.liquidSize(margin-right, 7, 17);
    }
    .currency {
      color: #1890a7;
      @include mixin.liquidSize(font-size, 16, 24);
      @include mixin.liquidSize(margin-top, 5, 6);
    }
  }

  //合計
  .total {
    display: flex;
    align-items: center;
    @include mixin.liquidSize(margin-top, 25, 30);
    @include mixin.liquidSize(padding-top, 15, 20);
    border-top: dotted 1px #c7c7c7;
    .head {
      @include mixin.liquidSize(font-size, 18, 32);
    }
    .num {
      color: #1890a7;
      @include mixin.liquidSize(font-size, 26, 32);
      @include mixin.liquidSize(margin-right, 7, 17);
      margin-left: auto;
    }
    .currency {
      color: #1890a7;
      @include mixin.liquidSize(font-size, 16, 24);
      @include mixin.liquidSize(margin-top, 5, 6);
    }
  }
}
</style>

<template>
  <button
    class="button"
    :class="[color, size]"
    v-html="btnTxt"
    @click="click"
    :disabled="disabled"
  ></button>
</template>

<script>
export default {
  name: "btn1",
  props: {
    btnTxt: String,
    color: String,
    size: String,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    click(e) {
      this.$emit("click", e);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@use "@/css/utility/_colors" as colors;
@use "@/css/utility/_mixin" as mixin;
@use "@/css/utility/_media" as media;
@use "@/css/utility/_font" as font;
@use "@/css/utility/_variables" as variables;
.button {
  width: 119px;
  height: 42px;
  border-radius: 21px;
  @include mixin.liquidSize(font-size, 15, 22);
  font-weight: 700; /* bold */
  letter-spacing: 0.01em;
}

.s {
  width: 127px;
}

.m {
  width: 167px;
}

.l {
  width: 201px;
}

.primary {
  color: #fff;
  background: #32a2b7;
  box-shadow: 0 4px 51px rgba(115, 158, 190, 0.6);
}

.white {
  color: #32a2b7;
  background: #fff;
  box-sizing: border-box;
  border: solid 1px #32a2b7;
}
</style>

<template lang="pug">
  section.category
    Header(seeing="estimate")
    main.admin
      .m-content-2
        breadcrumb(
          h2txt="見積情報"
          h2link=""
          h3txt=""
          h3link=""
          type="admin"
        )
        head3(ja="見積情報" en="Estimate")

        .list-controls
          .pager
            span.prev(@click="prev")
              img(src="@/assets/icon-left-1.svg")
            span.current {{ pages.page }}
            span.next(@click="next")
              img(src="@/assets/icon-right-1.svg")

        .list-header
          span.list-header__item.fixed 正式見積
          span.list-header__item.publishDate 発行日
          span.list-header__item.num 見積書No.
          span.list-header__item.client 顧客名
          span.list-header__item.price 金額
          span.list-header__item.send 見積送信
          span.list-header__item.preview 見る
          span.list-header__item.edit 編集
        .list.form-style-1
          .list-item(v-for="item,index in this.estimate" :key="index")
            .fixed
              label
                input(type="checkbox" v-model="item.status" readonly disabled)
                span
              span.m-sm 正式見積
            .publishDate
              span.m-sm.label-sm 発行日：
              |{{ item.created }}
            .num
              span.m-sm.label-sm 見積書No.：
              |{{ item.estimate_no }}
            .client
              span.m-sm.label-sm 顧客名：
              |{{ item.address }}
            .price {{ item.fix_price.toLocaleString() }}
              span.currency 円(税込)

            .list-item__btns
              .send
                router-link(to="" v-if="item.status")
                  btn2(type="mail")
              router-link(:to="'/admin/estimate/detail/' + item.id")
                btn2(type="preview")
              router-link(:to="'/admin/estimate/edit/' + item.id")
                btn2(type="edit")



        .btns
          btn(
            btnTxt="戻る"
            color="white"
            size="s"
            @click="back"
          )


        .pager
          span.prev(@click="prev")
            img(src="@/assets/icon-left-1.svg")
          span(v-for="num, index in pages.pages" :key="index" :class="{page: pages.page != num, current: pages.page == num}") {{ num }}
          span.next(@click="next")
            img(src="@/assets/icon-right-1.svg")

</template>

<script>
import Header from "@/components/admin/common/header.vue";
import head3 from "@/components/common/head-3.vue";
import btn from "@/components/admin/common/btn-1.vue";
import btn2 from "@/components/admin/common/btn-2.vue";
import breadcrumb from "@/components/common/breadcrumb.vue";
import { common } from "@/mixins/common.js";
import ApiClient from "@/lib/http.js";
export default {
  name: "Item",
  metaInfo() {
    return {
      title: "見積情報 | イベント備品レンタル倉庫 カリトク",
      meta: [
        { name: "description", content: "イベント備品レンタル倉庫 カリトク" },
        { name: "keywords", content: "" },
      ],
    };
  },
  components: {
    Header,
    head3,
    btn,
    btn2,
    breadcrumb,
  },
  mixins: [common],
  data: function () {
    return {
      estimate: [],
      page: 1,
      pages: {},
    };
  },
  async created() {
    this.getEstimate();
  },
  methods: {
    async getEstimate() {
      const api = new ApiClient();
      let data = await api.get("/admin/estimate/list/" + this.page);
      this.estimate = data.list;
      this.pages = data.page;
    },
    async prev() {
      if (this.page != 1) {
        this.page -= 1;
        this.getEstimate();
        this.windowTop();
      }
    },
    async next() {
      if (this.pages.next == 1) {
        this.page += 1;
        this.getEstimate();
        this.windowTop();
      }
    },
  },
};
</script>

<style scoped lang="scss">
@use "@/css/utility/_colors" as colors;
@use "@/css/utility/_mixin" as mixin;
@use "@/css/utility/_media" as media;
@use "@/css/utility/_font" as font;
@use "@/css/utility/_variables" as variables;
.head-blk {
  @include mixin.liquidSize(margin-bottom, 65, 40);
}

.list-controls {
  display: flex;
  align-items: center;
  .pager {
    .current {
      margin: 0 21px;
    }
    .prev {
      margin-right: 0;
    }
    .next {
      margin-left: 0;
    }
  }
}

.list-header {
  width: 100%;
  height: 59px;
  display: flex;
  align-items: center;
  box-shadow: 0 24px 29px rgba(0, 0, 0, 0.02);
  @include mixin.liquidSize(margin-bottom, 20, 20);
  @include media.max-screen(media.$breakpoint-sm) {
    display: none;
  }
  .send {
    @include mixin.liquidSize(width, 95, auto);
  }
  .preview {
    @include mixin.liquidSize(width, 95, auto);
  }
  .edit {
    @include mixin.liquidSize(width, 74, auto);
  }
}

.list-header,
.list-item {
  @include mixin.liquidSize(font-size, 20, 30);
  .label-sm {
    @include mixin.liquidSize(font-size, 20, 24);
    color: #868383;
  }
  .fixed {
    @include mixin.liquidSize(width, 78, 250);
    @include mixin.liquidSize(margin-bottom, 0, 40);
    display: flex;
    label {
      margin-right: 0px;
    }
  }
  .publishDate {
    @include mixin.liquidSize(width, 207, 450);
    @include mixin.liquidSize(margin-bottom, 0, 20);
  }
  .num {
    @include mixin.liquidSize(width, 187, 450);
    @include mixin.liquidSize(margin-bottom, 0, 20);
  }
  .client {
    @include mixin.liquidSize(width, 286, 450);
    @include mixin.liquidSize(margin-bottom, 0, 40);
  }
  .price {
    //width: 232px;
    @include mixin.liquidSize(width, 173, 700);
    display: inline-block;
    box-sizing: border-box;
    padding-right: 1em;
  }
}

.list-header__item {
  color: #868383;
  @include mixin.liquidSize(font-size, 14, 22);
  letter-spacing: 0.02em;
  position: relative;
  &.publishDate,
  &.num,
  &.client {
    &:after {
      content: "";
      display: inline-block;
      width: 13px;
      height: 7px;
      margin-left: 14px;
      background: {
        image: url(../../../assets/icon-tri-1.svg);
        size: contain;
        position: center;
        repeat: no-repeat;
      }
    }
  }
}

.list {
  @include mixin.liquidSize(margin-bottom, 50, 100);
}

.list-item {
  @include mixin.liquidSize(height, 90, auto);
  border-bottom: dotted 1px #dedede;
  @include media.max-screen(media.$breakpoint-sm) {
    padding: 30px 0 10px;
  }
  @include media.min-screen(media.$breakpoint-md) {
    display: flex;
    align-items: center;
  }

  .price {
    @include mixin.liquidSize(font-size, 20, 30);
    letter-spacing: 0.02em;
    color: #1890a7;
    text-align: right;
    .currency {
      @include mixin.liquidSize(font-size, 14, 22);
      letter-spacing: 0.04em;
    }
  }
}

.list-item__btns {
  margin-left: auto;
  display: flex;
  align-items: center;
  @include mixin.liquidSize(width, 261, 600);
  @include media.max-screen(media.$breakpoint-sm) {
    justify-content: flex-end;
    margin-top: 20px;
  }
  .send {
    @include mixin.liquidSize(width, 95, auto);
  }
  .mail,
  .preview {
    margin-right: 20px;
    @media screen and (min-width: 768px) and (max-width: 1400px) {
      margin-right: calc(20 / 1440 * 100vw);
    }
  }
}

.btns {
  display: flex;
  width: 100%;
  @include mixin.liquidSize(margin-bottom, 40, 60);
  @include media.max-screen(media.$breakpoint-sm) {
    justify-content: center;
    flex-wrap: wrap;
  }
  @include media.min-screen(media.$breakpoint-md) {
    justify-content: flex-end;
  }
  .button {
    @include media.max-screen(media.$breakpoint-sm) {
      margin: 0 10px 20px;
    }
    @include media.min-screen(media.$breakpoint-md) {
      margin: 0 0 0 20px;
    }
    @media screen and (min-width: 768px) and (max-width: 1400px) {
      margin-left: calc(20 / 1440 * 100vw);
    }
  }
}

.pager {
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  .current {
    background: #e5f9f9;
    width: 29px;
    height: 28px;
    @include mixin.liquidSize(font-size, 19, 30);
    letter-spacing: 0.04em;
    color: #1890a7;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
  }
  .prev,
  .next {
    width: 39px;
    height: 28px;
    box-sizing: border-box;
    border: solid 1px #696969;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .prev {
    margin-right: 14px;
  }
  .next {
    margin-left: 14px;
  }
  .current {
    margin: 0 8px;
  }
  .page {
    @include mixin.liquidSize(font-size, 20, 32);
    color: #7b7b7b;
    margin: 0 8px;
  }
}
</style>

<template lang="pug">
  .cart
    main.user
      Header
      .content

        .m-content
          breadcrumb(
            h2txt="見積編集"
            h2link=""
            h3txt="確認ページ"
            h3link=""
            type="admin"
          )



          #form.form-style-1
            .form-item
              .inner-item
                label.label.require 必須
                span.head お客さまメールアドレス：
                input.mail(type="text" v-model="estimate.mail")
                .error {{ errors.mailaddress ? errors.mailaddress[0] : ''}}
                .note
                  span.icon
                    img(src="@/assets/icon-q.svg")
                  |お客様のメールアドレスを入力下さい。

            .form-item
              .inner-item
                label.label.require 必須
                span.head 自動送信メール定形文タイトル：
                input.mailTitle(type="text" v-model="estimate.mailTitle")
                .error {{ errors.title ? errors.title[0] : ''}}
                .note
                  span.icon
                    img(src="@/assets/icon-q.svg")
                  |自動送信メール定形文のタイトルを入力下さい。

            .form-item
              .inner-item
                label.label.require 必須
                span.head 自動送信メール定形文本文：
                textarea.mailBody(v-model="estimate.mailBody")
                .error {{ errors.message ? errors.message[0] : ''}}
                .note
                  span.icon
                    img(src="@/assets/icon-q.svg")
                  |自動送信メール定形文の本文を入力下さい。

            .btns
              btn(
                btnTxt="戻る"
                color="white"
                size="s"
                @click="back"
              )
              btn(
                btnTxt="見積書PDFを見る"
                color="white"
                size="m"
                @click="PdfCheck"
              )
              btn(
                btnTxt="正式見積を送信"
                color="primary"
                size="l"
                @click="send"
                :disabled="disabled"
              )




</template>

<script>
// @ is an alias to /src
import Header from "@/components/admin/common/header.vue";
import breadcrumb from "@/components/common/breadcrumb.vue";
import btn from "@/components/admin/common/btn-1.vue";
import { common } from "@/mixins/common.js";
import ApiClient from "@/lib/http.js";
export default {
  name: "Confirm",
  metaInfo() {
    return {
      title: "確認ページ | 見積編集 | イベント備品レンタル倉庫 カリトク",
      meta: [
        { name: "description", content: "イベント備品レンタル倉庫 カリトク" },
        { name: "keywords", content: "" },
      ],
    };
  },
  components: {
    Header,
    breadcrumb,
    btn,
  },
  mixins: [common],
  data: function () {
    return {
      //見積りデータ
      estimate: {
        mail: "",
        mailTitle: "",
        mailBody: "",
      },
      errors: {},
      disabled: false,
    };
  },
  created: async function () {
    const api = new ApiClient();
    let setting = await api.get("/admin/setting");
    this.estimate.mailTitle = setting.detail.quotation_title;
    this.estimate.mailBody = setting.detail.quotation_body;

    let data = await api.get("/admin/estimate/detail/" + this.$route.params.id);
    this.estimate.mail = data.detail.mailaddress;
  },
  methods: {
    async PdfCheck() {
      let param = {
        id: this.$route.params.id,
      };
      const api = new ApiClient();
      let data = await api.post("/admin/estimate/pdf_check", param);
      window.open(data.pdfUrl, "_blank");
    },
    async send() {
      this.disabled = true;
      let param = {
        id: this.$route.params.id,
        mailaddress: this.estimate.mail,
        title: this.estimate.mailTitle,
        message: this.estimate.mailBody,
      };
      const api = new ApiClient();
      let data = await api.post("/admin/estimate/send", param);
      if (data.state == 204) {
        this.errors = data.errors;
        this.windowTop();
        this.disabled = false;
        return;
      }
      this.$router.push("/admin/estimate");
    },
  },
};
</script>

<style lang="scss" scoped>
@use "@/css/utility/_colors" as colors;
@use "@/css/utility/_mixin" as mixin;
@use "@/css/utility/_media" as media;
@use "@/css/utility/_font" as font;
@use "@/css/utility/_variables" as variables;

.content {
  background: #fafafa;
  @include mixin.liquidSize(padding, 55, 25);
}

//フォーム
#form {
  @include mixin.liquidSize(width, 1076, 670);
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  //@include mixin.liquidSize(padding, 55, 40);
  @include mixin.liquidSize(margin-bottom, 110, 100);
}

.form-item {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border-bottom: dotted 1px #c7c7c7;
  @include mixin.liquidSize(padding-top, 25, 0);
  @include mixin.liquidSize(padding-bottom, 25, 0);
  @include mixin.liquidSize(min-height, auto, 90);

  .inner-item {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    box-sizing: border-box;
    @include mixin.liquidSize(padding-top, 0, 50);
    @include mixin.liquidSize(padding-bottom, 0, 50);
    @include mixin.liquidSize(min-height, auto, 90);
    @include media.max-screen(media.$breakpoint-sm) {
      width: 100%;
      &:not(:last-of-type) {
        border-bottom: dotted 1px #c7c7c7;
      }
    }
  }

  .label {
    color: #fff;
    @include mixin.liquidSize(font-size, 13, 20);
    @include mixin.liquidSize(width, 41, 60);
    @include mixin.liquidSize(height, 20, 32);
    @include mixin.liquidSize(margin-right, 8, 10);
    @include mixin.liquidSize(margin-bottom, 0, 30);
    @include mixin.liquidSize(border-radius, 4, 8);
    white-space: nowrap;
    box-sizing: border-box;
    @include mixin.liquidSize(padding-left, 7, 10);
    @include mixin.liquidSize(padding-right, 6, 8);
    @include mixin.liquidSize(padding-top, 0, 1);
    @include mixin.liquidSize(padding-bottom, 1, 0);
    line-height: 1;
  }
  .require {
    background: #e57e7e;
  }
  .optional {
    background: #a5a5a5;
  }
  .head {
    @include mixin.liquidSize(font-size, 17, 26);
    @include mixin.liquidSize(margin-right, 10, 0);
    @include mixin.liquidSize(margin-bottom, 0, 30);
    line-height: 1;
  }
}

.labels {
  display: flex;
  align-items: center;
}

label {
  display: flex;
  align-items: center;
  @include mixin.liquidSize(font-size, 14, 24);
  @include mixin.liquidSize(margin-right, 14, 62);
  cursor: pointer;
  position: relative;
}

input {
  + span {
    border: solid 1px #adadad;
    @include mixin.liquidSize(width, 20, 26);
    @include mixin.liquidSize(height, 20, 26);
    display: inline-block;
    border-radius: 50%;
    position: relative;
    @include mixin.liquidSize(margin-right, 12, 12);
    box-sizing: border-box;
  }
  &:checked {
    + span {
      &:after {
        content: "";
        @include mixin.liquidSize(width, 12, 16);
        @include mixin.liquidSize(height, 12, 16);
        display: inline-block;
        border-radius: 50%;
        background: #1890a7;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  &[type="text"] {
    @include mixin.liquidSize(font-size, 15, 22);
    background: #f5f6f8;
    @include mixin.liquidSize(height, 45, 62);
    box-sizing: border-box;
    @include mixin.liquidSize(padding-left, 20, 20);
    @include mixin.liquidSize(padding-right, 20, 20);
    @include mixin.liquidSize(border-radius, 6, 6);
  }

  &.mail,
  &.mailTitle {
    @include mixin.liquidSize(width, 730, 670);
    @include media.min-screen(media.$breakpoint-md) {
      margin-left: auto;
    }
  }
}

textarea {
  &.mailBody {
    @include mixin.liquidSize(width, 730, 670);
    @include mixin.liquidSize(height, 197, 300);
    @include media.min-screen(media.$breakpoint-md) {
      margin-left: auto;
    }
  }
}

.note {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 32px;
  box-sizing: border-box;
  line-height: 1.75;
  color: #6f6f6f;
  @include mixin.liquidSize(margin-top, 15, 10);
  @include mixin.liquidSize(font-size, 13, 23);
  min-height: 22px;
  @include mixin.liquidSize(width, 730, auto);
  @include media.min-screen(media.$breakpoint-md) {
    margin-left: auto;
  }
  .icon {
    width: 22px;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.btns {
  display: flex;
  width: 100%;
  @include mixin.liquidSize(margin-top, 40, 60);
  @include mixin.liquidSize(margin-bottom, 30, 60);
  @include media.max-screen(media.$breakpoint-sm) {
    justify-content: center;
    flex-wrap: wrap;
  }
  @include media.min-screen(media.$breakpoint-md) {
    justify-content: flex-end;
  }
  .button {
    @include media.max-screen(media.$breakpoint-sm) {
      margin: 0 10px 20px;
    }
    @include media.min-screen(media.$breakpoint-md) {
      margin: 0 0 0 20px;
    }
  }
}

.additional-head {
  width: 100%;
  display: block;
  @include mixin.liquidSize(font-size, 18, 26);
  font-weight: 600; /* semibold */
  @include mixin.liquidSize(padding-top, 0, 30);
  @include mixin.liquidSize(margin-bottom, 30, 30);
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
  transition: all 0.2s ease-in-out;
}

.fade-enter-to,
.fade-leave {
  opacity: 1;
  transition: all 0.2s ease-in-out;
}
</style>

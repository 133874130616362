<template lang="pug">
  .cart
    main.user
      Header
      .kv
        h2.ttl 見積りカート
      .content

        .m-content
          breadcrumb(
            h2txt="見積りカート"
            h2link=""
            h3txt=""
            h3link=""
          )


          cart(
            @changePeriod="getLeftDays"
            @update="update"
            @deleteItem="deleteItem"
            @periodError="periodError"
            :cartItem="cart"
            :errors="errors"
            v-model="postParam"
          )



          #form
            .form-item
              label.label.require 必須
              span.head 仮予約：
              .labels
                label
                  span
                  input(type="radio" name="reserve" v-model="postParam.reserve" value="1" @click="reserveState = true")
                  span
                  |する
                label
                  input(type="radio" name="reserve" v-model="postParam.reserve" value="0" @click="reserveState = false")
                  span
                  |しない
              //-.error(v-if="errors.reserve") {{ errors.reserve[0]}}
            .form-item
              .inner-item
                label.label.require 必須
                span.head 属性：
                .labels
                  label
                    span
                    input(type="radio" name="company" v-model="postParam.attribute" value="1")
                    span
                    |法人
                  label
                    input(type="radio" name="company" v-model="postParam.attribute" value="2")
                    span
                    |個人
              //-.error(v-if="errors.attribute") {{ errors.attribute[0]}}
              .inner-item.name
                label.label.optional 任意
                span.head 宛名：
                input(type="text" name="name" placeholder="株式会社カリトク 総務部御中" v-model="postParam.address")
                //-.error(v-if="errors.address") {{ errors.address[0]}}
                .labels
                  label
                    span
                    input(type="radio" name="title" v-model="postParam.honorific_title" value="1")
                    span
                    |様
                  label
                    input(type="radio" name="title" v-model="postParam.honorific_title" value="2")
                    span
                    |御中
                  //-.error(v-if="errors.honorific_title") {{ errors.honorific_title[0]}}

            .form-item
              .inner-item
                label.label.optional(v-if="!reserveState") 任意
                label.label.require(v-if="reserveState") 必須
                span.head 電話：
                input(type="tel" name="tel" placeholder="090-1111-2222" v-model="postParam.tel")
                //-.error(v-if="errors.tel") {{ errors.tel[0]}}
              .inner-item
                label.label.require 必須
                span.head メール：
                input(type="mail" name="mail" placeholder="info@kari-toku.jp" v-model="postParam.mailaddress")
                //-.error(v-if="errors.mailaddress") {{ errors.mailaddress[0]}}

            .form-item.delivery
              .gr-1
                label.label.require 必須
                span.head 受け渡し方法：
              .gr-2
                .gr-2__item
                  span.head （開始時）
                  .labels
                    label
                      span
                      input(type="radio" name="deliveryStart" v-model="postParam.pick_up" value="1")
                      span
                      |引き取り
                    label
                      input(type="radio" name="deliveryStart" v-model="postParam.pick_up" value="2")
                      span
                      |配送・郵送を希望する
                  //-.error(v-if="errors.pick_up") {{ errors.pick_up[0]}}
                .gr-2__item
                  span.head （返却時）
                  .labels
                    label
                      span
                      input(type="radio" name="deliveryEnd" v-model="postParam.give_back" value="1")
                      span
                      |来社
                    label
                      input(type="radio" name="deliveryEnd" v-model="postParam.give_back" value="2")
                      span
                      |配送・郵送を希望する
                  //-.error(v-if="errors.give_back") {{ errors.give_back[0]}}
              ul.notes
                li
                  span.head ※ 配送料別途…
                  |配送をご希望される場合、当社で別途お見積が必要になります。
                li
                  span.head ※ 引き取り場所…
                  a(href="https://www.google.com/maps/place/%E3%80%92557-0061+%E5%A4%A7%E9%98%AA%E5%BA%9C%E5%A4%A7%E9%98%AA%E5%B8%82%E8%A5%BF%E6%88%90%E5%8C%BA%E5%8C%97%E6%B4%A5%E5%AE%88%EF%BC%91%E4%B8%81%E7%9B%AE%EF%BC%98%E2%88%92%EF%BC%98/@34.6555104,135.4831436,17z/data=!3m1!4b1!4m5!3m4!1s0x6000e79edae5b6b7:0x56f7b086e21d808d!8m2!3d34.6555104!4d135.4831436" target="blank") 大阪市西成区北津守 1-8-8
                  span.icon
                    img(src="@/assets/icon-map.svg")


            .btn(@click="submit")
              span.icon
                img(src="@/assets/icon-cart-2.svg")
              span.txt 見積りを作成する





        flow
        contact
      Footer
      transition(name="fade")
        overlay(
          v-if="overlayState == 'alert'"
          type="A"
          icon="exclamation.svg"
          ttl="ご確認下さい"
          msg="1０営業日以内のご予約はシステム上承ることができません。お申込み可能な場合もございますので、詳しくはお電話かメールにてお問い合わせ下さい。"
          additionalBtn=false
          additionalBtnLink="/cart/"
          additionalBtnTxt=""
          @overlayOff="overlayOff"
        )
        //- TODO ダウンロードURL
        overlay(
          v-if="overlayState == 'submit'"
          type="B"
          icon="submit.png"
          head="お見積り"
          ttl="お見積り頂き、<br class='m-sm'>ありがとうございます。"
          msg="ご登録のアドレス宛にお見積りのPDFを添付したメールを送信しました。<br>なお、お見積りのPDFは、<br class='m-sm'>下の「ダウンロードする」ボタンからも<br class='m-sm'>お受け取りが可能です。"
          additionalBtn=true
          :additionalBtnLink="pdfUrl"
          additionalBtnTxt="ダウンロードする"
          @overlayOff="overlayOff"
        )
        overlay(
          v-if="overlayState == 'error'"
          type="error"
          icon="submit.png"
          ttl="入力内容をご確認ください。"
          :msg="errorMsg"
          additionalBtn=false
          @overlayOff="overlayOff"
        )
</template>

<script>
// @ is an alias to /src
import Header from "@/components/user/common/header.vue";
import Footer from "@/components/user/common/footer.vue";
import flow from "@/components/user/common/flow.vue";
import contact from "@/components/user/common/contact.vue";
import breadcrumb from "@/components/common/breadcrumb.vue";
import cart from "@/components/user/cart/cart.vue";
import overlay from "@/components/common/overlay.vue";
import ApiClient from "@/lib/http.js";
export default {
  name: "Cart",
  metaInfo() {
    return {
      title: "見積りカート | イベント備品レンタル倉庫 カリトク",
      meta: [
        { name: "description", content: "イベント備品レンタル倉庫 カリトク" },
        { name: "keywords", content: "" },
      ],
    };
  },
  components: {
    Header,
    flow,
    contact,
    Footer,
    breadcrumb,
    cart,
    overlay,
  },
  data: function () {
    return {
      reserveState: false,
      overlayState: "",
      leftDays: 0,
      cart: [],
      postParam: {
        start_date: "",
        end_date: "",
        reserve: "",
        attribute: "",
        address: "",
        honorific_title: "",
        tel: "",
        mailaddress: "",
        pick_up: "",
        give_back: "",
        tax: 0,
        subtotal: 0,
        fix_price: 0,
        carts: [],
      },
      errors: {},
      errorMsg: "",
      pdfUrl: "",
    };
  },
  methods: {
    //overlay からのイベント
    overlayOff: function () {
      this.overlayState = false;
    },
    //cart からのイベント
    getLeftDays: function (v) {
      this.leftDays = v;
      console.log("getLeftDays");
    },
    //レンタル期間が0以下
    periodError: function () {
      console.log("periodError");
      this.overlayState = "error";
      this.errorMsg = "終了日は開始日以降に設定してください";
    },
    //見積り作成ボタンをクリックした時
    submit: async function () {
      //10日以内の場合はアラート
      //TODO 定休日無しなので、10営業日 = 10日？ → Yes
      if (this.leftDays < 10) {
        this.overlayState = "alert";
        return;
      }

      //バリデーションエラーの場合、処理を止めています。
      let result = await this.createEstimate();
      if (!result) {
        this.overlayState = "error";
        return;
      }

      //10営業日以上なら見積り作成
      this.overlayState = "submit";
    },
    async getCart() {
      const api = new ApiClient();
      let data = await api.get("/cart/list", null, null, false);
      this.cart = data.list;
    },
    async update(item) {
      let param = {
        id: item.id,
        amount: item.amount,
        support: item.support,
      };
      const api = new ApiClient();
      await api.post("/cart/update", param, false);
    },
    async deleteItem(id) {
      let param = {
        id: id,
      };
      const api = new ApiClient();
      await api.post("/cart/delete", param, false);
      this.getCart();
    },
    async createEstimate() {
      this.postParam.carts = this.cart;
      const api = new ApiClient();
      let data = await api.post("/estimate/save", this.postParam, false);
      let result = true;
      this.errors = {};
      if (data.state == 204) {
        result = false;
        this.errors = data.errors;
        this.errorMsg = "";
        for (let key in this.errors) {
          for (let i = 0; i < this.errors[key].length; i++) {
            var label = "";
            if (key == "reserve") label = "仮予約：";
            if (key == "attribute") label = "属性：";
            if (key == "tel") label = "電話番号：";
            if (key == "mailaddress") label = "メールアドレス：";
            if (key == "pick_up") label = "受け渡し方法(開始時)：";
            if (key == "give_back") label = "受け渡し方法(返却時)：";
            this.errorMsg += label + this.errors[key][i] + "<br>";
          }
        }
      }
      if (data.state == 200) {
        this.pdfUrl = data.pdfUrl;
      }
      return result;
    },
  },
  async created() {
    this.getCart();
  },
};
</script>

<style lang="scss" scoped>
@use "@/css/utility/_colors" as colors;
@use "@/css/utility/_mixin" as mixin;
@use "@/css/utility/_media" as media;
@use "@/css/utility/_font" as font;
@use "@/css/utility/_variables" as variables;
.kv {
  width: 100%;
  @include mixin.liquidSize(height, 217, 188);
  background: {
    image: url(../assets/bg-cart.jpg);
    position: center;
    size: cover;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  @include mixin.liquidSize(padding-bottom, 10, 10);
  .ttl {
    @include mixin.liquidSize(font-size, 25, 30);
    font-weight: 700; /* bold */
    letter-spacing: 0.04em;
    line-height: 1;
    @include mixin.lhFix(1);
  }
}
.content {
  background: #fafafa;
}

//フォーム
#form {
  @include mixin.liquidSize(width, 954, 700);
  background: #fff;
  box-sizing: border-box;
  @include mixin.liquidSize(padding, 55, 40);
  @include mixin.liquidSize(margin-bottom, 110, 100);
}

.form-item {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border-bottom: dotted 1px #c7c7c7;
  @include mixin.liquidSize(padding-top, 25, 0);
  @include mixin.liquidSize(padding-bottom, 25, 0);
  @include mixin.liquidSize(min-height, auto, 90);

  .inner-item {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    box-sizing: border-box;
    @include mixin.liquidSize(padding-top, 0, 20);
    @include mixin.liquidSize(padding-bottom, 10, 20);
    @include mixin.liquidSize(min-height, auto, 90);
    @include media.max-screen(media.$breakpoint-sm) {
      width: 100%;
      &:not(:last-of-type) {
        border-bottom: dotted 1px #c7c7c7;
      }
    }
    //例外 宛名
    &.name {
      .labels {
        @include mixin.liquidSize(margin-left, 0, 200);
      }
    }
  }

  .label {
    color: #fff;
    @include mixin.liquidSize(font-size, 13, 20);
    @include mixin.liquidSize(width, 41, 60);
    @include mixin.liquidSize(height, 20, 32);
    @include mixin.liquidSize(margin-right, 8, 10);
    @include mixin.liquidSize(margin-bottom, 0, 0);
    @include mixin.liquidSize(border-radius, 4, 8);
    white-space: nowrap;
    box-sizing: border-box;
    @include mixin.liquidSize(padding-left, 7, 10);
    @include mixin.liquidSize(padding-right, 6, 8);
    @include mixin.liquidSize(padding-top, 0, 1);
    @include mixin.liquidSize(padding-bottom, 1, 0);
    line-height: 1;
  }
  .require {
    background: #e57e7e;
  }
  .optional {
    background: #a5a5a5;
  }
  .head {
    @include mixin.liquidSize(font-size, 17, 26);
    @include mixin.liquidSize(margin-right, 10, 0);
    @include mixin.liquidSize(width, auto, 120);
    line-height: 1;
  }
  //例外 受渡方法
  &.delivery {
    @include mixin.liquidSize(padding-top, 40, 35);
    @include mixin.liquidSize(padding-bottom, 25, 35);
    .gr-1 {
      display: flex;
      align-items: center;
      @include mixin.liquidSize(margin-bottom, auto, 40);
    }
    .head {
      @include mixin.liquidSize(width, auto, auto);
    }
    .gr-2 {
      .gr-2__item {
        display: flex;
        align-items: center;
        @include mixin.liquidSize(width, auto, auto);
        &:not(last-of-type) {
          @include mixin.liquidSize(margin-bottom, 25, 30);
        }
        label {
          @include mixin.liquidSize(margin-right, 20, 20);
        }
        .head {
          @include mixin.liquidSize(margin-right, 10, 22);
        }
      }
    }
  }
}

.labels {
  display: flex;
  align-items: center;
}
label {
  display: flex;
  align-items: center;
  @include mixin.liquidSize(font-size, 14, 24);
  @include mixin.liquidSize(margin-right, 14, 62);
  cursor: pointer;
  position: relative;
}

input {
  + span {
    border: solid 1px #adadad;
    @include mixin.liquidSize(width, 20, 26);
    @include mixin.liquidSize(height, 20, 26);
    display: inline-block;
    border-radius: 50%;
    position: relative;
    @include mixin.liquidSize(margin-right, 12, 12);
    box-sizing: border-box;
  }
  &:checked {
    + span {
      &:after {
        content: "";
        @include mixin.liquidSize(width, 12, 16);
        @include mixin.liquidSize(height, 12, 16);
        display: inline-block;
        border-radius: 50%;
        background: #1890a7;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
  &[type="text"],
  &[type="mail"],
  &[type="tel"] {
    @include mixin.liquidSize(font-size, 15, 22);
    background: #f5f6f8;
    @include mixin.liquidSize(height, 45, 62);
    box-sizing: border-box;
    @include mixin.liquidSize(padding-left, 20, 30);
    @include mixin.liquidSize(padding-right, 20, 30);
    @include mixin.liquidSize(border-radius, 6, 6);
  }
  &[name="name"] {
    @include mixin.liquidSize(width, 325, 430);
    @include mixin.liquidSize(margin-right, 15, 0);
    @include mixin.liquidSize(margin-bottom, 0, 20);
  }
  &[name="tel"] {
    @include mixin.liquidSize(width, 260, 430);
    @include mixin.liquidSize(margin-right, 38, 0);
  }
  &[name="mail"] {
    @include mixin.liquidSize(width, 309, 430);
  }
}

.notes {
  @include mixin.liquidSize(font-size, 14, 20);
  .head {
    @include mixin.liquidSize(font-size, 15, 20);
    @include mixin.liquidSize(margin-right, 0, 0);
  }
  li {
    &:not(:last-of-type) {
      @include mixin.liquidSize(margin-bottom, 15, 30);
    }
  }
  a {
    color: #1890a7;
    border-bottom: dotted 1px #1890a7;
  }
  .icon {
    @include mixin.liquidSize(width, 18, 29);
    @include mixin.liquidSize(height, 20, 32);
    @include mixin.liquidSize(margin-left, 6, 12);
    display: inline-block;
    img {
      width: 100%;
    }
  }
}

.btn {
  background: #1890a7;
  color: #fff;
  @include mixin.liquidSize(width, 280, 620);
  @include mixin.liquidSize(height, 55, 86);
  display: flex;
  align-items: center;
  cursor: pointer;
  box-sizing: border-box;
  position: relative;
  @include mixin.liquidSize(padding-left, 55, 86);
  @include mixin.liquidSize(margin-top, 40, 60);
  @include media.max-screen(media.$breakpoint-sm) {
    margin-left: auto;
    margin-right: auto;
  }
  @include media.min-screen(media.$breakpoint-md) {
    margin-left: auto;
    &:hover {
      .icon {
        width: 100%;
      }
    }
  }
  .icon {
    @include mixin.liquidSize(width, 55, 86);
    @include mixin.liquidSize(height, 55, 86);
    background: #12869b;
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    transition: all 0.4s ease-in-out;
    @include mixin.liquidSize(padding-left, 16, 24);
    box-sizing: border-box;
    img {
      @include mixin.liquidSize(width, 18, 34);
    }
  }
  .txt {
    @include mixin.liquidSize(font-size, 14, 26);
    margin: 0 auto;
    letter-spacing: 0.035em;
    position: relative;
    z-index: 2;
  }
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
  transition: all 0.2s ease-in-out;
}

.fade-enter-to,
.fade-leave {
  opacity: 1;
  transition: all 0.2s ease-in-out;
}
</style>

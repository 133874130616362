<template lang="pug">
  .category
    main.user
      Header
      categorySlider
      .m-content
        breadcrumb(
          h2txt="検索結果"
          h2link=""
        )

      #meta
        .meta-inner

          .m-content
            h2.ttl
              span.ja 検索結果


      #product
        .m-content
          ul.list
            li.list-item(v-for="product in this.products" :key="product")
              router-link(:to="/product/ + product.id")
                .img(:style="{backgroundImage: 'url(\"' + product.img + '\")'}")
                  //-img(:src="product.img")
                span.name {{ product.name }}
                .price
                  span.num {{ product.price.toLocaleString() }}
                  span.currency 円(税込)

      section#scene
        .m-content
          h3.scene-head
            head1(ja="シーン別に探す" en="Scene" type="h")
        scene
      flow
      contact
      Footer
</template>

<script>
// @ is an alias to /src
import Header from "@/components/user/common/header.vue";
import Footer from "@/components/user/common/footer.vue";
import flow from "@/components/user/common/flow.vue";
import contact from "@/components/user/common/contact.vue";
import breadcrumb from "@/components/common/breadcrumb.vue";
import categorySlider from "@/components/user/common/categorySlider.vue";
import scene from "@/components/user/common/scene.vue";
import head1 from "@/components/common/head-1.vue";
import ApiClient from "@/lib/http.js";

export default {
  name: "Search",
  metaInfo() {
    let catTtl = "検索結果";
    return {
      title: catTtl + " | イベント備品レンタル倉庫 カリトク",
      meta: [
        { name: "description", content: "イベント備品レンタル倉庫 カリトク" },
        { name: "keywords", content: "" },
      ],
    };
  },
  components: {
    Header,
    flow,
    contact,
    Footer,
    breadcrumb,
    categorySlider,
    scene,
    head1,
  },
  data: function () {
    return {
      products: [],
      categoryList: [],
      category: {},
    };
  },
  computed: {
    loadImg(path) {
      return require("@/assets/" + path);
    },
  },
  async created() {
    this.categoryList = await this.$store.dispatch("getCategoryList");
    this.getItem();
    this.getCategory();
  },
  methods: {
    async getItem() {
      let param = {
        search: this.$route.params.keyword,
      };
      const api = new ApiClient();
      let data = await api.post("/item/list", param, false);
      this.products = data.list;
      console.log(this.products);
    },
    async getCategory() {
      if (this.$route.params.id > 0) {
        const api = new ApiClient();
        let data = await api.get(
          "/category/detail/" + this.$route.params.id,
          null,
          null,
          false
        );
        this.category = data.detail;
      }
    },
  },
};
</script>

<style scoped lang="scss">
@use "@/css/utility/_colors" as colors;
@use "@/css/utility/_mixin" as mixin;
@use "@/css/utility/_media" as media;
@use "@/css/utility/_font" as font;
@use "@/css/utility/_variables" as variables;
//-シーン別に探す
.scene-head {
  @include mixin.liquidSize(margin-bottom, 30, 45);
}

#scene {
  @include mixin.liquidSize(padding-top, 120, 120);
  @include mixin.liquidSize(padding-bottom, 60, 50);
}

#meta {
  @include mixin.liquidSize(margin-bottom, 60, 60);
  .meta-inner {
    position: relative;
  }
  .img {
    @include mixin.liquidSize(width, 660, 750);
    @include mixin.liquidSize(height, 215, 245);
    @include mixin.liquidSize(margin-bottom, 0, 60);
    @include media.min-screen(media.$breakpoint-md) {
      position: absolute;
      left: 50%;
      top: 0;
    }
  }
  .ttl {
    @include mixin.liquidSize(margin-bottom, 40, 60);
    @include media.min-screen(media.$breakpoint-md) {
      width: 50%;
    }
    .ja {
      @include mixin.liquidSize(font-size, 25, 40);
      display: block;
    }
    .en {
      color: #848484;
      @include mixin.liquidSize(font-size, 17, 26);
    }
  }
  .desc {
    @include mixin.liquidSize(font-size, 15, 26);
    line-height: 1.6;
    font-weight: 500; /* medium */
  }
}

.list {
  display: flex;
  flex-wrap: wrap;
}

.list-item {
  @include mixin.liquidSize(width, 307, 337);
  @include mixin.liquidSize(margin-bottom, 60, 60);
  &:not(:nth-of-type(4n)) {
    @include mixin.liquidSize(margin-right, 30, 0);
  }
  @include media.max-screen(media.$breakpoint-sm) {
    &:nth-of-type(odd) {
      margin-right: auto;
    }
  }
  .img {
    @include mixin.liquidSize(width, 307, 307);
    @include mixin.liquidSize(height, 194, 194);
    @include mixin.liquidSize(margin-bottom, 10, 14);
    background: {
      size: cover;
      position: center;
      repeat: no-repeat;
    }
  }
  .name {
    color: #000;
    @include mixin.liquidSize(font-size, 17, 26);
    display: block;
    @include mixin.liquidSize(margin-bottom, 3, 6);
  }
  .price {
    color: #1890a7;
    .num {
      @include mixin.liquidSize(font-size, 16, 26);
      @include mixin.liquidSize(margin-right, 3, 6);
    }
    .currency {
      @include mixin.liquidSize(font-size, 13, 20);
    }
  }
}
</style>

<template lang="pug">
  section.category
    Header(seeing="item")
    main.admin
      .m-content-2
        breadcrumb(
          h2txt="商品情報"
          h2link="/admin/item/"
          h3txt="商品登録"
          h3link=""
          type="admin"
        )
        head3(ja="商品登録" en="Item Add")


        section.form
          .form-style-1
            //- 商品名
            .row
              span.label.required 必須
              span.head 商品名：
              input.name(type="text" v-model="item.name" :class="{ error: errors.name ? errors.name[0] : '' }")
              //-.error {{ errors.name ? errors.name[0] : '' }}

            //- サイズ、金額
            transition-group(name="fade" tag="div")
              .row(v-for="size, index in item.size" :key="index")
                .size-blk.inner-blk
                  span.label 任意
                  span.head サイズ・仕様等：
                  input.size(type="text" v-model="item.size[index].size")
                  .note
                .price-blk.inner-blk
                  span.label.required 必須
                  span.head 金額：
                  input.price(type="text" v-model="item.size[index].price" :class="{ error: errors.ItemDetails ? errors.ItemDetails[index].price ? errors.ItemDetails[index].price[0] : '': '' }")
                  |円（税込み）
                  //-.error {{ errors.ItemDetails ? errors.ItemDetails[index].price ? errors.ItemDetails[index].price[0] : '': '' }}
                  .note
                    span.icon
                      img(src="@/assets/icon-q.svg")
                    |金額は、1日目のレンタル料金を入力して下さい。
                .code-blk.inner-blk
                  span.label.required 必須
                  span.head 商品コード：
                  input.code(type="text" v-model="item.size[index].item_code" :class="{ error: errors.ItemDetails ? errors.ItemDetails[index].item_code ? errors.ItemDetails[index].item_code[0] :'' : ''}")
                  //-.error {{ errors.ItemDetails ? errors.ItemDetails[index].item_code ? errors.ItemDetails[index].item_code[0] :'' : '' }}
                  .note
                    span.icon
                      img(src="@/assets/icon-q.svg")
                    |商品コードは、一意でユニークなコードを入力して下さい。

                .inner-btns(v-if="index + 1 === item.size.length")
                  btn(
                    v-if="1 < item.size.length"
                    @click="deleteSize(item.size.length)"
                    btnTxt="削除する"
                    color="white"
                    size="m"
                  )
                  btn(
                    @click="addSize"
                    btnTxt="さらに追加する"
                    color="white"
                    size="m"
                  )

            //- 2日目以降の料金
            .row.discount-blk
              span.label.required 必須
              span.head 2日目以降の料金：
              input.discount(type="text" v-model="item.discount" :class="{ error: errors.after_second_day ? errors.after_second_day[0] : ''}")
              |%
              //-.error {{ errors.after_second_day ? errors.after_second_day[0] : '' }}
              .note
                span.icon
                  img(src="@/assets/icon-q.svg")
                |デフォルトでは、2日目以降、上記金額の10％が一日ごとに加算されます。

            //- カテゴリ：
            .row.category-blk
              span.label.required 必須
              span.head カテゴリ：
              select.category(v-model="item.category" :class="{ error: errors.category_id ? errors.category_id[0] : '' }")
                option(v-for="category in this.category" :value="category") {{category.title}}
              //-.error {{ errors.category_id ? errors.category_id[0] : '' }}

            //- セット商品：
            transition(name="fade")
              .row.set-blk(v-if="item.category ? item.category.title === 'セット商品' : false")
                .meta
                  span.head セット商品：
                  p.desc セット商品の商品コードを入力して下さい

                transition-group(name="fade" tag="div")
                  .inner-blk.set-blk(v-for="setItem, index in item.set" :key="index")
                    .set-blk__inner
                      span.label 任意
                      span.head 商品コード：
                      input.set(type="text" v-model="item.set[index].code")

                    .set-blk__inner
                      span.label 任意
                      span.head 初期値の個数：
                      input.defAmount(type="text" v-model="item.set[index].defAmount")

                    .inner-btns(v-if="index + 1 === item.set.length")
                      btn(
                        v-if="1 < item.set.length"
                        @click="deleteSetItem(item.set.length)"
                        btnTxt="削除する"
                        color="white"
                        size="m"
                      )
                      btn(
                        @click="addSetItem"
                        btnTxt="さらに追加する"
                        color="white"
                        size="m"
                      )

            //- 組み立てサポート：
            .row.category-blk
              span.label.required 必須
              span.head 組み立てサポート：
              .radios(:class="{ error: errors.support ? errors.support[0] : '' }")
                label
                  span
                  input(type="radio" name="support" value="1" v-model="item.support")
                  span
                  |する
                label
                  input(type="radio" name="support" value="0" v-model="item.support")
                  span
                  |しない
              //-.error {{ errors.support ? errors.support[0] : '' }}

            //- アテンション：
            .row.attention-blk
              span.label 任意
              span.head アテンション：
              textarea.attention(v-model="item.attention")

            //- 商品説明文：
            .row.desc-blk
              span.label.required 必須
              span.head 商品説明文：
              textarea.desc(v-model="item.desc" :class="{error: errors.support ? errors.support[0] : '' }" placeholder="例：競技会場、スポーツ大会、イベント会場などで使用される大型のイベント用テント。商品のお届けから設置・撤去までおまかせ。")
              //-.error {{ errors.support ? errors.support[0] : '' }}

            //- 仕様・サイズ等：
            .row.spec-blk
              span.label.required 必須
              span.head 仕様・サイズ等：
              .spec-item(v-for="spec, index in item.spec" :key="index")
                input.specHead(type="text" :class="{error: errors.ItemDescriptions ? errors.ItemDescriptions[index].title ? errors.ItemDescriptions[index].title[0] : '' : ''}" v-model="item.spec[index].title" placeholder="タイトル（例：サイズ表示）")
                //-.error {{ errors.ItemDescriptions ? errors.ItemDescriptions[index].title ? errors.ItemDescriptions[index].title[0] : '' : '' }}
                textarea.specDesc(v-model="item.spec[index].body" :class="{error: errors.ItemDescriptions ? errors.ItemDescriptions[index].body ? errors.ItemDescriptions[index].body[0] : '' : '' }" placeholder="説明文（例：１Ｋ＝Ｗ１７８０ １．５Ｋ＝Ｗ２６７０ ２Ｋ＝Ｗ３５６０）")
                //-.error {{ errors.ItemDescriptions ? errors.ItemDescriptions[index].body ? errors.ItemDescriptions[index].body[0] : '' : '' }}

                .inner-btns(v-if="index + 1 === item.spec.length")
                  btn(
                    v-if="1 < item.spec.length"
                    @click="deleteSpecItem(item.spec.length)"
                    btnTxt="削除する"
                    color="white"
                    size="m"
                  )
                  btn(
                    @click="addSpecItem"
                    btnTxt="さらに追加する"
                    color="white"
                    size="m"
                  )

            //- 商品画像
            transition-group.row.img-blk(name="fade" tag="div")
              .img-item(
                v-for="imgItem, index in imgDisp"
                :key="index"
                draggable="true"
                @dragstart="dragstart(imgItem, $event)"
                @dragenter="dragenter(imgItem)"
                @dragover.stop.prevent="dragover"
                @dragend.stop.prevent="dragend"
              )
                input(:id="'img-input-' + index" type="file" ref="itemImage" @change="imgChange(index, $event)")
                label.img-input(:for="'img-input-' + index") 商品画像

                .img-preview(v-if="imgItem.url")
                  img(:src="imgItem.url")
                btn(
                  v-if="imgItem.url"
                  @click="deleteImg(index)"
                  btnTxt="削除する"
                  color="white"
                  size="s"
                )

            //- 関連商品：
            .row.related-blk
              .meta
                span.label 任意
                span.head 関連商品：
                p.desc 商品コードを入力して下さい

              .related-list
                .inner-blk.related-item(v-for="relatedItem, index in item.relatedItem" :key="index")
                  input.related(type="text" v-model="item.relatedItem[index].code")
              .note
                span.icon
                  img(src="@/assets/icon-q.svg")
                |ここで登録した関連商品はデフォルトで登録した商品より並び順が先になります。関連商品は、上の段から左から右へ順に中抜けができないよう埋めて下さい。





        .btns
          btn(
            btnTxt="戻る"
            color="white"
            size="s"
            @click="back"
          )
          btn(
            btnTxt="プレビュー"
            color="white"
            size="m"
            @click="previewItem"
          )
          btn(
            btnTxt="この内容で保存する"
            color="primary"
            size="l"
            @click="saveItem"
          )





</template>

<script>
import Header from "@/components/admin/common/header.vue";
import head3 from "@/components/common/head-3.vue";
import btn from "@/components/admin/common/btn-1.vue";
import breadcrumb from "@/components/common/breadcrumb.vue";
import { common } from "@/mixins/common.js";
import ApiClient from "@/lib/http.js";
export default {
  name: "Category",
  metaInfo() {
    return {
      title: "商品登録 | イベント備品レンタル倉庫 カリトク",
      meta: [
        { name: "description", content: "イベント備品レンタル倉庫 カリトク" },
        { name: "keywords", content: "" },
      ],
    };
  },
  components: {
    Header,
    head3,
    btn,
    breadcrumb,
  },
  mixins: [common],
  computed: {
    imgDisp() {
      const imgList = this.item.img;
      return imgList.sort((a, b) => a.order - b.order);
    },
  },
  data: function () {
    return {
      draggingItem: null,
      //商品情報
      item: {
        id: "",
        name: "",
        size: [
          {
            size: "",
            price: null,
            item_code: "",
          },
        ],
        discount: 10, //2日目以降の割引率
        set: [
          {
            code: "",
            defAmount: 0,
          },
        ],
        support: null,
        attention: "",
        desc: "", //商品説明文
        spec: [
          //仕様・サイズ等：
          {
            title: "",
            body: "",
          },
        ],
        img: [
          {
            url: "",
            order: 1,
          },
          {
            url: "",
            order: 2,
          },
          {
            url: "",
            order: 3,
          },
          {
            url: "",
            order: 4,
          },
        ],
        relatedItem: [
          {
            code: "",
          },
          {
            code: "",
          },
          {
            code: "",
          },
          {
            code: "",
          },
          {
            code: "",
          },
          {
            code: "",
          },
          {
            code: "",
          },
          {
            code: "",
          },
          {
            code: "",
          },
          {
            code: "",
          },
          {
            code: "",
          },
          {
            code: "",
          },
        ],
      },
      //カテゴリ一覧
      category: [],
      errors: {},
    };
  },
  async created() {
    this.getCategory();
  },
  methods: {
    addSize: function () {
      this.item.size.push({
        size: "",
        price: "",
        item_code: "",
      });
    },
    deleteSize: function (index) {
      let i = index - 1;
      this.item.size.splice(i, 1);
    },
    addSetItem: function () {
      this.item.set.push({
        code: "",
        defAmount: 0,
      });
    },
    deleteSetItem: function (index) {
      let i = index - 1;
      this.item.set.splice(i, 1);
    },
    addSpecItem: function () {
      this.item.spec.push({
        title: "",
        body: "",
      });
    },
    deleteSpecItem: function (index) {
      let i = index - 1;
      this.item.spec.splice(i, 1);
    },

    imgChange: function (index, event) {
      console.log(event);
      let file = this.$refs.itemImage[index].files[0];
      this.item.img[index].url = URL.createObjectURL(file);

      this.item.img[index].img_name = file.name;
      let reader = new FileReader();
      reader.onload = (e) => {
        this.item.img[index].img = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    deleteImg: function (index) {
      this.item.img[index].url = "";
    },

    dragstart(item, e) {
      this.draggingItem = item; // ドラッグ中の要素を保持
      e.dataTransfer.effectAllowed = "move"; // 移動モードに設定
      e.target.style.opacity = 0.5; // ドラッグ中要素のスタイルを変更
    },
    dragenter(item) {
      // ドラッグ中の要素とドラッグ先の要素の表示順を入れ替える
      [item.order, this.draggingItem.order] = [
        this.draggingItem.order,
        item.order,
      ];
    },
    dragover(e) {
      e.dataTransfer.dropEffect = "move"; // 移動モードに設定
    },
    dragend(e) {
      e.target.style.opacity = 1; // ドラッグ中要素のスタイルを変更（元に戻す）
      this.draggingItem = null; // ドラッグ中の要素をクリア
    },
    async getCategory() {
      const api = new ApiClient();
      let data = await api.get("/admin/category");
      this.category = data.list;
    },
    async saveItem() {
      let param = {
        category_id: this.item.category ? this.item.category.id : "",
        name: this.item.name,
        after_second_day: this.item.discount,
        support: this.item.support,
        attention: this.item.attention,
        description: this.item.desc,
        details: this.item.size,
        descriptions: this.item.spec,
        sets: [],
        images: [],
        connections: [],
      };
      for (let i = 0; i < this.item.img.length; i++) {
        if (this.item.img[i].img) {
          param.images.push({
            img_name: this.item.img[i].img_name,
            img: this.item.img[i].img,
          });
        }
      }
      for (let i = 0; i < this.item.relatedItem.length; i++) {
        if (this.item.relatedItem[i].code != "") {
          param.connections.push({
            item_code: this.item.relatedItem[i].code,
          });
        }
      }
      for (let i = 0; i < this.item.set.length; i++) {
        console.log(this.item.set[i]);
        if (this.item.set[i].code != "" || this.item.set[i].defAmount != "") {
          param.sets.push({
            item_code: this.item.set[i].code,
            count: this.item.set[i].defAmount,
          });
        }
      }
      const api = new ApiClient();
      let data = await api.post("/admin/item/save", param);
      if (data.state == 204) {
        this.errors = data.errors;
        this.windowTop();
        return;
      }
      this.$router.push("/admin/item");
    },
    async previewItem() {
      const param = this.createParam();
      const api = new ApiClient();
      await api.post("/admin/item/preview/save", param);

      var id = 0;
      if (this.item.id) {
        id = this.item.id;
      }
      window.open("/admin/item/preview/cache/" + id);
    },
    createParam() {
      let param = {
        id: this.item.id,
        category_id: this.item.category ? this.item.category.id : "",
        name: this.item.name,
        after_second_day: this.item.discount,
        support: this.item.support,
        attention: this.item.attention,
        description: this.item.desc,
        details: this.item.size,
        descriptions: this.item.spec,
        sets: [],
        images: [],
        connections: [],
        deleteDetails: this.deleteDetails,
      };
      for (let i = 0; i < this.item.img.length; i++) {
        if (this.item.img[i].img) {
          param.images.push({
            img_name: this.item.img[i].img_name,
            img: this.item.img[i].img,
          });
        }
      }
      for (let i = 0; i < this.item.relatedItem.length; i++) {
        if (this.item.relatedItem[i].code != "") {
          param.connections.push({
            item_code: this.item.relatedItem[i].code,
          });
        }
      }
      for (let i = 0; i < this.item.set.length; i++) {
        if (this.item.set[i].code != "" || this.item.set[i].defAmount != "") {
          param.sets.push({
            item_code: this.item.set[i].code,
            count: this.item.set[i].defAmount,
          });
        }
      }
      return param;
    },
  },
};
</script>

<style scoped lang="scss">
@use "@/css/utility/_colors" as colors;
@use "@/css/utility/_mixin" as mixin;
@use "@/css/utility/_media" as media;
@use "@/css/utility/_font" as font;
@use "@/css/utility/_variables" as variables;
.head-blk {
  @include mixin.liquidSize(margin-bottom, 65, 40);
}

.btns {
  display: flex;
  width: 100%;
  @include mixin.liquidSize(width, 950, 700);
  @include mixin.liquidSize(margin-bottom, 30, 60);
  @include media.max-screen(media.$breakpoint-sm) {
    justify-content: center;
    flex-wrap: wrap;
  }
  @include media.min-screen(media.$breakpoint-md) {
    justify-content: flex-end;
  }
  .button {
    @include media.max-screen(media.$breakpoint-sm) {
      margin: 0 10px 20px;
    }
    @include media.min-screen(media.$breakpoint-md) {
      margin: 0 0 0 20px;
    }
  }
}

.note {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 32px;
  box-sizing: border-box;
  line-height: 1.75;
  color: #6f6f6f;
  @include mixin.liquidSize(margin-top, 5, 10);
  @include mixin.liquidSize(font-size, 13, 23);
  min-height: 22px;
  @include media.min-screen(media.$breakpoint-md) {
    width: 100%;
    //margin-left: auto;
  }
  @media screen and (min-width: 768px) and (max-width: 1400px) {
    padding-left: calc(32 / 1440 * 100vw);
  }
  .icon {
    width: 22px;
    position: absolute;
    top: 0;
    left: 0;
    @media screen and (min-width: 768px) and (max-width: 1400px) {
      width: calc(22 / 1440 * 100vw);
      top: calc(3 / 1440 * 100vw);
    }
  }
}

.form {
  background: #fcfcfc;
  @include mixin.liquidSize(width, 1185, 700);
  box-sizing: border-box;
  @include mixin.liquidSize(padding-left, 105, 40);
  @include mixin.liquidSize(padding-right, 105, 40);
  padding-top: 55px;

  // 行
  .row {
    padding: 25px 0;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    @include mixin.liquidSize(font-size, 14, 22);
    &:not(:last-of-type) {
      border-bottom: dotted 1px #dedede;
    }
  }

  .label,
  .head {
    @include mixin.liquidSize(margin-bottom, 0, 30);
  }

  .label {
    width: 41px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    @include mixin.liquidSize(font-size, 13, 22);
    border-radius: 4px;
    color: #fff;
    margin-right: 14px;
    background: #b7b7b7;
    &.required {
      background: #e57e7e;
    }
    @media screen and (min-width: 768px) and (max-width: 1400px) {
      width: calc(41 / 1440 * 100vw);
      height: calc(26 / 1440 * 100vw);
      margin-right: calc(14 / 1440 * 100vw);
    }
  }
  .head {
    @include mixin.liquidSize(font-size, 17, 26);
    letter-spacing: 0.04em;
    @include mixin.liquidSize(margin-right, 15, 20);
  }
  .form-style-1 {
    @include mixin.liquidSize(margin-bottom, 40, 60);
    @include mixin.liquidSize(width, 843, auto);
  }

  .inner-btns {
    display: flex;
    width: 100%;
    @include mixin.liquidSize(margin-top, 30, 30);
    button {
      @include mixin.liquidSize(margin-right, 30, 40);
      @include mixin.liquidSize(width, 167, 270);
    }
  }

  .img-input,
  .img-preview {
    @include mixin.liquidSize(width, 190, 250);
    @include mixin.liquidSize(height, 190, 250);
    box-sizing: border-box;
    border: dashed 1px #b5b5b5;
  }
  .img-input {
    display: block;
    background: {
      image: url(../../../assets/icon-plus-1.svg);
      repeat: no-repeat;
      position: top 56px center;
    }
    text-align: center;
    @include mixin.liquidSize(font-size, 14, 22);
    color: #767676;
    padding: 15px;
    margin-right: 0;
    @include mixin.liquidSize(padding-top, 115, 115);
    @include media.max-screen(media.$breakpoint-sm) {
      background-position: center center;
      text-indent: -10000px;
    }
    @include media.min-screen(media.$breakpoint-md) {
      background-position: top 56px center;
    }
    @media screen and (min-width: 768px) and (max-width: 1400px) {
      background-position: top calc(56 / 1440 * 80vw) center;
      background-size: 3vw auto;
      padding-top: calc(115 / 1440 * 100vw);
    }
  }
  .img-preview {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    pointer-events: none;
    background: #fcfcfc;
  }

  .inner-blk {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    @include mixin.liquidSize(font-size, 14, 22);
  }

  .size-blk,
  .code-blk {
    @include mixin.liquidSize(width, 465, 620);
    @include mixin.liquidSize(margin-bottom, 10, 40);
  }

  .price-blk {
    @include mixin.liquidSize(width, 375, 620);
    @include mixin.liquidSize(margin-bottom, 10, 40);
  }

  .discount-blk {
    .note {
      @include mixin.liquidSize(width, 560, 620);
      @include mixin.liquidSize(margin-left, 45, 0);
    }
  }

  .attention-blk,
  .desc-blk {
    .label {
      @include media.min-screen(media.$breakpoint-md) {
        margin-top: 12px;
        margin-bottom: auto;
      }
    }
    .head {
      @include media.min-screen(media.$breakpoint-md) {
        margin-top: 9px;
        margin-bottom: auto;
      }
    }
  }

  .set-blk {
    @include mixin.liquidSize(padding-top, 15, 30);
    &:not(:last-of-type) {
      @include mixin.liquidSize(padding-bottom, 15, 30);
      border-bottom: dotted 1px #dedede;
    }

    .meta {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      @include mixin.liquidSize(margin-bottom, 10, 10);
      .desc {
        color: #a09090;
        @include mixin.liquidSize(font-size, 15, 22);
      }
    }
    .inner-btns {
      @include mixin.liquidSize(padding-top, 20, 30);
    }

    .set-blk__inner {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      .head {
        @include mixin.liquidSize(width, auto, 400);
      }
    }
  }

  .spec-blk {
    .label {
      @include media.min-screen(media.$breakpoint-md) {
        margin-top: 12px;
        margin-bottom: auto;
      }
      @media screen and (min-width: 768px) and (max-width: 1400px) {
        margin-top: calc(12 / 1440 * 100vw);
      }
    }
    .head {
      @include media.min-screen(media.$breakpoint-md) {
        margin-top: 9px;
        margin-bottom: auto;
      }
      @media screen and (min-width: 768px) and (max-width: 1400px) {
        margin-top: calc(9 / 1440 * 100vw);
      }
    }
  }

  .spec-item {
    @include mixin.liquidSize(width, 630, 620);
    &:not(:first-of-type) {
      @include mixin.liquidSize(margin-top, 40, 30);
      margin-left: auto;
    }
  }

  .img-blk {
    align-items: flex-start;
  }
  .img-item {
    position: relative;
    @include media.min-screen(media.$breakpoint-md) {
      &:not(:nth-of-type(4n)) {
        margin-right: 24px;
      }
    }
    @media screen and (min-width: 768px) and (max-width: 1400px) {
      &:not(:nth-of-type(4n)) {
        margin-right: calc(24 / 1440 * 100vw);
      }
    }
    button {
      margin: 15px auto;
      display: block;
    }
  }

  .related-blk {
    .meta {
      display: flex;
      align-items: center;
      @include mixin.liquidSize(margin-bottom, 25, 40);
    }
  }

  .related-list {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }

  .related-item {
    @include media.max-screen(media.$breakpoint-sm) {
      &:nth-of-type(odd) {
        margin-right: 20px;
      }
    }
    @include media.min-screen(media.$breakpoint-md) {
      &:not(:nth-of-type(4n)) {
        margin-right: 27px;
      }
    }
    @media screen and (min-width: 768px) and (max-width: 1400px) {
      &:not(:nth-of-type(4n)) {
        margin-right: calc(27 / 1440 * 100vw);
      }
    }
  }

  input {
    &.name {
      width: 496px;
      max-width: 100%;
      @media screen and (min-width: 768px) and (max-width: 1400px) {
        width: calc(496 / 1440 * 100vw);
      }
    }
    &.size {
      @include mixin.liquidSize(width, 167, 620);
    }
    &.price {
      @include mixin.liquidSize(width, 140, 400);
      text-align: right;
      margin-right: 10px;
      @media screen and (min-width: 768px) and (max-width: 1400px) {
        margin-right: calc(10 / 1440 * 100vw);
      }
    }
    &.discount {
      @include mixin.liquidSize(width, 78, 400);
      text-align: right;
      margin-right: 10px;
      @media screen and (min-width: 768px) and (max-width: 1400px) {
        margin-right: calc(10 / 1440 * 100vw);
      }
    }
    &.set {
      @include mixin.liquidSize(width, 167, 400);
      @include mixin.liquidSize(margin-right, 60, 0);
      @include mixin.liquidSize(margin-bottom, 0, 50);
    }
    &.defAmount {
      @include mixin.liquidSize(width, 65, 130);
      text-align: right;
      margin-right: 10px;
      @media screen and (min-width: 768px) and (max-width: 1400px) {
        margin-right: calc(10 / 1440 * 100vw);
      }
    }
    &.specHead {
      @include mixin.liquidSize(width, 630, 620);
      @include mixin.liquidSize(margin-bottom, 20, 30);
    }
    &.related {
      @include mixin.liquidSize(width, 190, 290);
      @include mixin.liquidSize(margin-bottom, 30, 30);
    }
  }
  select {
    &.category {
      box-sizing: border-box;
      border: solid 1px #12aec0;
      @include mixin.liquidSize(width, 300, 620);
      @include mixin.liquidSize(height, 36, 70);
      @include mixin.liquidSize(font-size, 16, 22);
      border-radius: 5px;
      padding: 0 2em 0 1.3em;
      background: {
        color: #fff;
        image: url(../../../assets/icon-tri-1.svg);
        repeat: no-repeat;
        position: center right 15px;
      }
    }
  }
  textarea {
    &.attention {
      @include mixin.liquidSize(width, 630, 620);
      @include mixin.liquidSize(height, 152, 300);
      @include media.min-screen(media.$breakpoint-md) {
        margin-left: auto;
      }
    }
    &.desc {
      @include mixin.liquidSize(width, 630, 620);
      @include mixin.liquidSize(height, 197, 300);
      @include media.min-screen(media.$breakpoint-md) {
        margin-left: auto;
      }
    }
    &.specDesc {
      @include mixin.liquidSize(width, 630, 620);
    }
  }
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
  transition: all 0.5s ease-in-out;
}

.fade-enter-to,
.fade-leave {
  opacity: 1;
  transition: all 0.5s ease-in-out;
}
</style>

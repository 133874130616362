<template lang="pug">
  section.category
    Header(seeing="item")
    main.admin
      .m-content-2
        breadcrumb(
          h2txt="商品情報"
          h2link=""
          h3txt=""
          h3link=""
          type="admin"
        )
        head3(ja="商品情報" en="Item List")

        .list-controls
          .sort
            span.label 絞り込み：
            .sort-item 全て
          .pager
            span.prev(@click="prev")
              img(src="@/assets/icon-left-1.svg")
            span.current {{ pages.page }}
            span.next(@click="next")
              img(src="@/assets/icon-right-1.svg")

        .list-header
          span.list-header__item.name 商品名
          span.list-header__item.category カテゴリ
          span.list-header__item.code 商品コード
          span.list-header__item.price 金額

        .list
          .list-item(v-for="item,index in this.items" :key="item[index]")
            .name
              span.name-txt {{ item.name }}
              span.name-size(v-if="item.size") {{ item.size }}
            .category {{ item.title }}
            .code {{ item.item_code }}
            .price {{ item.price.toLocaleString() }}
              span.currency 円(税込)
            .list-item__btns
              //-.list-item__sort
                span.up(:class="{top: index == 0}")
                  img(src="@/assets/icon-sort-1-up.svg")
                .separator
                span.down
                  img(src="@/assets/icon-sort-1-down.svg")
              .delete(@click="deleteItem(item.id)") 削除
              router-link(:to="'/admin/item/preview/' + item.id" target="_blank")
                btn2(type="preview")
              router-link(:to="'/admin/item/edit/' + item.id")
                btn2(type="edit")



        .btns
          btn(
            btnTxt="戻る"
            color="white"
            size="s"
            @click="back"
          )
          router-link(to="/admin/item/add/")
            btn(
              btnTxt="商品を登録する"
              color="primary"
              size="l"
            )

        .pager
          span.prev(@click="prev")
            img(src="@/assets/icon-left-1.svg")
          span(v-for="num, index in pages.pages" :key="index" :class="{page: pages.page != num, current: pages.page == num}") {{ num }}
          span.next(@click="next")
            img(src="@/assets/icon-right-1.svg")

</template>

<script>
import Header from "@/components/admin/common/header.vue";
import head3 from "@/components/common/head-3.vue";
import btn from "@/components/admin/common/btn-1.vue";
import btn2 from "@/components/admin/common/btn-2.vue";
import breadcrumb from "@/components/common/breadcrumb.vue";
import { common } from "@/mixins/common.js";
import ApiClient from "@/lib/http.js";
export default {
  name: "Item",
  metaInfo() {
    return {
      title: "商品情報 | イベント備品レンタル倉庫 カリトク",
      meta: [
        { name: "description", content: "イベント備品レンタル倉庫 カリトク" },
        { name: "keywords", content: "" },
      ],
    };
  },
  components: {
    Header,
    head3,
    btn,
    btn2,
    breadcrumb,
  },
  mixins: [common],
  data: function () {
    return {
      items: [],
      page: 1,
      pages: {},
    };
  },
  async created() {
    this.getItem();
  },
  methods: {
    async getItem() {
      const api = new ApiClient();
      let data = await api.get("/admin/item/list/" + this.page);
      this.items = data.list;
      this.pages = data.page;
    },
    async deleteItem(id) {
      const param = {
        id: id,
      };
      const api = new ApiClient();
      await api.post("/admin/item/delete", param);
      this.getItem();
    },
    async prev() {
      if (this.page != 1) {
        this.page -= 1;
        this.getItem();
        this.windowTop();
      }
    },
    async next() {
      if (this.pages.next == 1) {
        this.page += 1;
        this.getItem();
        this.windowTop();
      }
    },
  },
};
</script>

<style scoped lang="scss">
@use "@/css/utility/_colors" as colors;
@use "@/css/utility/_mixin" as mixin;
@use "@/css/utility/_media" as media;
@use "@/css/utility/_font" as font;
@use "@/css/utility/_variables" as variables;
.head-blk {
  @include mixin.liquidSize(margin-bottom, 65, 40);
}

.list-controls {
  display: flex;
  align-items: center;
  .sort {
    display: flex;
    align-items: center;
    .label {
      color: #a7b1b8;
      letter-spacing: 0.06em;
      @include mixin.liquidSize(font-size, 14, 22);
    }
    .sort-item {
      @include mixin.liquidSize(font-size, 14, 22);
      color: #737d7e;
      letter-spacing: 0.06em;
      position: relative;
      box-sizing: border-box;
      padding: 9px 22px 0 14px;
      height: 37px;
      background: #f0f5f5;
      border-radius: 19px;
      cursor: pointer;
      &:before {
        content: "";
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: #b2bec6;
        display: inline-block;
        margin-right: 9px;
      }
    }
  }
  .pager {
    .current {
      margin: 0 21px;
    }
    .prev {
      margin-right: 0;
    }
    .next {
      margin-left: 0;
    }
  }
}

.list-header {
  width: 100%;
  height: 59px;
  display: flex;
  align-items: center;
  box-shadow: 0 24px 29px rgba(0, 0, 0, 0.02);
  @include mixin.liquidSize(margin-bottom, 20, 20);
  @include media.max-screen(media.$breakpoint-sm) {
    display: none;
  }
}

.list-header,
.list-item {
  .name {
    width: 373px;
    @include mixin.liquidSize(width, 373, 700);
  }
  .category {
    width: 161px;
    @include mixin.liquidSize(width, 161, 500);
    display: inline-block;
    @include mixin.liquidSize(margin-bottom, 0, 20);
    box-sizing: border-box;
    @include mixin.liquidSize(padding-left, 0, 50);
  }
  .code {
    width: 192px;
    @include mixin.liquidSize(width, 192, 200);
    display: inline-block;
    @include mixin.liquidSize(margin-bottom, 0, 20);
  }
  .price {
    width: 232px;
    @include mixin.liquidSize(width, 232, 700);
    display: inline-block;
    @include media.max-screen(media.$breakpoint-sm) {
      text-align: right;
    }
  }
}

.list-header__item {
  color: #868383;
  @include mixin.liquidSize(font-size, 14, 22);
  letter-spacing: 0.02em;
  position: relative;
  &.name,
  &.category,
  &.code {
    &:after {
      content: "";
      display: inline-block;
      width: 13px;
      height: 7px;
      margin-left: 14px;
      background: {
        image: url(../../../assets/icon-tri-1.svg);
        size: contain;
        position: center;
        repeat: no-repeat;
      }
    }
  }
}

.list {
  @include mixin.liquidSize(margin-bottom, 50, 100);
}

.list-item {
  @include mixin.liquidSize(height, 90, auto);
  border-bottom: dotted 1px #dedede;
  @include media.max-screen(media.$breakpoint-sm) {
    padding: 30px 0 10px;
  }
  @include media.min-screen(media.$breakpoint-md) {
    display: flex;
    align-items: center;
  }
  .name {
    color: #4e4e4e;
    position: relative;
    box-sizing: border-box;
    display: inline-block;

    @include mixin.liquidSize(padding-left, 34, 50);
    @include mixin.liquidSize(margin-bottom, 0, 20);
    &:before {
      content: "";
      width: 3px;
      height: 100%;
      background: #12aec0;
      position: absolute;
      left: 0;
      top: 0;
    }
    .name-txt {
      @include mixin.liquidSize(font-size, 20, 26);
      font-weight: 700; /* bold */
      letter-spacing: 0.04em;
      @include mixin.liquidSize(font-size, 20, 36);
    }
    .name-size {
      @include mixin.liquidSize(font-size, 15, 30);
      @include media.max-screen(media.$breakpoint-sm) {
        margin-left: 30px;
      }
      @include media.min-screen(media.$breakpoint-md) {
        display: block;
      }
    }
  }
  .category,
  .code {
    @include mixin.liquidSize(font-size, 19, 30);
    letter-spacing: 0.04em;
    padding-right: 1em;
    box-sizing: border-box;
  }
  .code {
    @include media.max-screen(media.$breakpoint-sm) {
      text-align: right;
      padding-right: 0;
    }
  }
  .price {
    @include mixin.liquidSize(font-size, 20, 26);
    letter-spacing: 0.02em;
    color: #1890a7;
    .currency {
      @include mixin.liquidSize(font-size, 14, 22);
      letter-spacing: 0.04em;
    }
  }
}

.list-item__btns {
  margin-left: auto;
  display: flex;
  align-items: center;
  @include mixin.liquidSize(width, 234, 600);
  @include media.max-screen(media.$breakpoint-sm) {
    justify-content: flex-end;
    margin-top: 20px;
  }
  .delete {
    margin: 0 34px 0 0;
    color: #44b9d0;
    @include mixin.liquidSize(font-size, 15, 22);
    letter-spacing: 0.04em;
    white-space: nowrap;
    @media screen and (min-width: 768px) and (max-width: 1400px) {
      margin-right: calc(34 / 1440 * 100vw);
    }
  }
  .preview {
    margin-right: 20px;
    @media screen and (min-width: 768px) and (max-width: 1400px) {
      margin-right: calc(20 / 1440 * 100vw);
    }
  }
}

.list-item__sort {
  display: flex;
  align-items: center;
  @include media.min-screen(media.$breakpoint-md) {
    flex-direction: column;
    align-items: center;
    width: 23px;
  }
  .up,
  .down {
    width: 23px;
    height: 33px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    @include media.max-screen(media.$breakpoint-sm) {
      margin: 0 10px;
      padding: 5px;
    }
    &.top,
    &.bottom {
      pointer-events: none;
      opacity: 0.41;
    }
  }
  .separator {
    width: 100%;
    height: 0;
    border-bottom: dotted 1px #12aec0;
    opacity: 0.38;
    @include media.max-screen(media.$breakpoint-sm) {
      display: none;
    }
  }
}

.btns {
  display: flex;
  width: 100%;
  @include mixin.liquidSize(margin-bottom, 40, 60);
  @include media.max-screen(media.$breakpoint-sm) {
    justify-content: center;
    flex-wrap: wrap;
  }
  @include media.min-screen(media.$breakpoint-md) {
    justify-content: flex-end;
  }
  .button {
    @include media.max-screen(media.$breakpoint-sm) {
      margin: 0 10px 20px;
    }
    @include media.min-screen(media.$breakpoint-md) {
      margin: 0 0 0 20px;
    }
  }
}

.pager {
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  .current {
    background: #e5f9f9;
    width: 29px;
    height: 28px;
    @include mixin.liquidSize(font-size, 19, 27);
    letter-spacing: 0.04em;
    color: #1890a7;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
  }
  .prev,
  .next {
    width: 39px;
    height: 28px;
    box-sizing: border-box;
    border: solid 1px #696969;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .prev {
    margin-right: 14px;
  }
  .next {
    margin-left: 14px;
  }
  .current {
    margin: 0 8px;
  }
  .page {
    @include mixin.liquidSize(font-size, 20, 26);
    color: #7b7b7b;
    margin: 0 8px;
  }
}
</style>

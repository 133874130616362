<template lang="pug">
  .about
    main.user
      Header
      section.kv
      section.content
        .m-content
          .breadcrumb
            breadcrumb(
              h2txt="お取引の流れ"
              h2link=""
              h3txt=""
              h3link=""
            )

          section
            h2.head
              head2(ja="お取引の流れ" en="Flow")
            p.desc お取引の流れをご説明いたします。
              br
              |カリトクでは、お見積りから発注、レンタル用品のお届けまで、弊社の担当スタッフがスピーディーに対応致します。


            section.flow-blk
              h3.flow-blk__head
                span.txt 「お見積り」から「発注」までの流れ
              span.flow-blk__label お急ぎの場合
              ul.list.hasBorder
                li.list-item
                  .list-img
                    img(src="@/assets/img-flow-1.png")
                  span.list-head 見積りを作成
                  p.list-desc お求めの商品をショッピングカートにお入れ頂き、お見積りを作成します。お見積は、自動で作成されメールでお届け致します。
                li.list-item
                  .list-img
                    img(src="@/assets/img-flow-2.png")
                  span.list-head 仮予約
                  p.list-desc お見積り作成時に仮予約をして、商品を仮抑えすることができます。こちらの手順を飛ばして、お電話からも仮予約・正式見積りを取る事ができます。
                li.list-item
                  .list-img
                    img(src="@/assets/img-flow-3.png")
                  span.list-head 正式見積り
                  p.list-desc お電話またはメールからお問合せ頂いたお客様には弊社スタッフがご連絡して正式なお見積りを作成します。
                li.list-item
                  .list-img
                    img(src="@/assets/img-flow-4.png")
                  span.list-head 正式発注
                  p.list-desc 正式見積りの内容をご確認頂いてから正式発注を承ります。


              span.flow-blk__label お見積りのみの場合
              ul.list
                li.list-item
                  .list-img
                    img(src="@/assets/img-flow-1.png")
                  span.list-head 見積りを作成
                  p.list-desc お求めの商品をショッピングカートにお入れ頂き、お見積りを作成します。お見積は、自動で作成されメールでお届け致します。
                li.list-item
                  .list-img
                    img(src="@/assets/img-flow-2-2.png")
                  span.list-head お見積りをメールでお届け
                  p.list-desc お見積を作成後に、ご登録のメールアドレスにお見積りをお届けいたします。ご不明な点やご質問などございましたらお気軽にお電話かメールにてお問い合わせ下さい。
                li.list-item
                  .list-img
                    img(src="@/assets/img-flow-2-3.png")
                  span.list-head 正式見積りはお電話で
                  p.list-desc 正式なお見積り、発注ははお電話にて承ります。在庫確認、送料、組み立てサポートなど、お気軽に担当のスタッフにお尋ね下さい。お客様からのお電話をお待ちしております。



            section.flow-blk
              h3.flow-blk__head
                span.txt 商品の受け取りから返却までの流れ
              ul.list
                li.list-item
                  .list-img
                    img(src="@/assets/img-flow-3-1.png")
                  span.list-head 受け渡し方法の選択
                  p.list-desc 発注の際に配送またはお引取りからご希望の受け渡し方法をお選び下さい。
                    span.hasBorder 商品受領前に請求書でのお振込または引取時にご精算をお願いいたします。
                li.list-item
                  .list-img
                    img(src="@/assets/img-flow-3-2.png")
                  span.list-head お受け取り
                  p.list-desc 当社指定の引取場所までお越しいただくか、配送にて商品を受け取り下さい。
                    br
                    |お受け取り方法は、郵送、配送、お引取りからお選びいただけます。
                    router-link.link(:to="{name: 'Delivery', hash: '#delivery'}" )
                      span.icon
                        img(src="@/assets/icon-arrow-2.svg")
                      span.txt 受け渡し方法について
                li.list-item
                  .list-img
                    img(src="@/assets/img-flow-3-3.png")
                  span.list-head イベント当日
                  p.list-desc お電話またはメールからお問合せ頂いたお客様には弊社スタッフがご連絡して正式なお見積りを作成します。
                li.list-item
                  .list-img
                    img(src="@/assets/img-flow-3-4.png")
                  span.list-head 返却
                  p.list-desc 正式見積りの内容をご確認頂いてから正式発注を承ります。
                    router-link.link(:to="{name: 'Delivery', hash: '#delivery'}" )
                      span.icon
                        img(src="@/assets/icon-arrow-2.svg")
                      span.txt 受け渡し方法について

        section.bnrs-blk
          //- バナー
          bnrs(seeing="flow")

        contact
        Footer
</template>

<script>
// @ is an alias to /src
import Header from "@/components/user/common/header.vue";
import Footer from "@/components/user/common/footer.vue";
import contact from "@/components/user/common/contact.vue";
import breadcrumb from "@/components/common/breadcrumb.vue";
import head2 from "@/components/common/head-2.vue";
import bnrs from "@/components/user/common/bnrs.vue";
export default {
  name: "About",
  metaInfo: {
    title: "お取引の流れ | イベント備品レンタル倉庫 カリトク",
    meta: [
      { name: "description", content: "イベント備品レンタル倉庫 カリトク" },
      { name: "keywords", content: "" },
    ],
  },
  components: {
    Header,
    contact,
    Footer,
    breadcrumb,
    head2,
    bnrs,
  },
};
</script>
<style lang="scss" scoped>
@use "@/css/utility/_colors" as colors;
@use "@/css/utility/_mixin" as mixin;
@use "@/css/utility/_media" as media;
@use "@/css/utility/_font" as font;
@use "@/css/utility/_variables" as variables;
.kv {
  width: 100%;
  @include mixin.liquidSize(height, 193, 188);
  background: {
    image: url(../assets/bg-flow.jpg);
    position: center;
    size: cover;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

.head {
  @include mixin.liquidSize(margin-bottom, 45, 80);
}

.desc {
  line-height: 2;
  @include mixin.liquidSize(font-size, 15, 26);
  @include mixin.liquidSize(margin-bottom, 70, 60);
}

.list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  @include mixin.liquidSize(padding-bottom, 85, 0);
  &.hasBorder {
    border-bottom: dotted 1px #c3deda;
    @include mixin.liquidSize(margin-bottom, 65, 60);
  }
}

.list-item {
  @include mixin.liquidSize(width, 250, 300);
  @include mixin.liquidSize(margin-left, 25, 0);
  @include mixin.liquidSize(margin-right, 25, 0);
  @include mixin.liquidSize(margin-bottom, 0, 100);
  position: relative;
  @include media.max-screen(media.$breakpoint-sm) {
    &:nth-of-type(odd) {
      margin-right: auto;
    }
    &:nth-of-type(even) {
      .list-img {
        &:after {
          display: none;
        }
      }
    }
  }

  &:not(:last-of-type) {
    .list-img {
      &:after {
        content: "";
        @include mixin.liquidSize(width, 27, 24);
        @include mixin.liquidSize(height, 27, 24);
        position: absolute;
        @include mixin.liquidSize(right, -10, -4);
        top: 50%;
        transform: translate(100%, -50%);
        background: {
          image: url(../assets/icon-arrow-3.svg);
          size: contain;
          repeat: no-repeat;
        }
      }
    }
  }

  &:before {
    content: "";
    @include mixin.liquidSize(width, 42, 42);
    @include mixin.liquidSize(height, 42, 42);
    position: absolute;
    top: 0;
    left: 0;
    background: {
      size: contain;
      repeat: no-repeat;
    }
  }
  @for $i from 1 through 4 {
    &:nth-of-type(#{$i}) {
      &:before {
        background-image: url(../assets/flow-num-#{$i}.svg);
      }
    }
  }
}

.list-img {
  @include mixin.liquidSize(height, 224, 224);
  position: relative;
  img {
    max-width: none;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: auto;
    height: 100%;
  }
}

.list-head {
  display: block;
  color: #50aabb;
  font-weight: 700; /* bold */
  @include mixin.liquidSize(font-size, 18, 25);
  text-align: center;
  @include mixin.lhFix(1);
  @include mixin.liquidSize(margin-bottom, 20, 15);
}

.list-desc {
  font-weight: 500; /* medium */
  @include mixin.liquidSize(font-size, 15, 24);
  @include mixin.lhFix(2);
  line-height: 2;
  box-sizing: border-box;
  color: #3e3e3e;
  .hasBorder {
    text-decoration: underline;
  }
  .link {
    color: #50aabb;
    display: inline-block;
    @include mixin.liquidSize(margin-top, 10, 10);
    .icon {
      position: relative;
      @include mixin.liquidSize(top, 8, 12);
      @include mixin.liquidSize(width, 17, 28);
      @include mixin.liquidSize(height, 16, 28);
      display: inline-block;
      @include mixin.liquidSize(margin-right, 6, 6);
    }
    .txt {
      @include mixin.liquidSize(font-size, 15, 24);
    }
  }
}

.flow-blk {
  background: #f7f9f9;
  box-sizing: border-box;
  @include mixin.liquidSize(border-radius, 21, 20);
  @include mixin.liquidSize(padding-top, 80, 60);
  @include mixin.liquidSize(padding-bottom, 80, 0);
  @include mixin.liquidSize(padding-left, 30, 30);
  @include mixin.liquidSize(padding-right, 30, 30);
  @include mixin.liquidSize(margin-bottom, 40, 60);
}

.flow-blk__head {
  text-align: center;
  @include mixin.liquidSize(margin-bottom, 50, 80);
  .txt {
    color: #1890a7;
    border-bottom: dotted 1px #1890a7;
    @include mixin.liquidSize(font-size, 24, 30);
    padding-bottom: 6px;
    display: inline-block;
  }
}

.flow-blk__label {
  @include mixin.liquidSize(font-size, 17, 26);
  font-weight: 700; /* bold */
  color: #1890a7;
  background: #fff;
  border: solid 1px #1890a7;
  @include mixin.liquidSize(border-radius, 22, 40);
  @include mixin.liquidSize(width, 231, 400);
  @include mixin.liquidSize(height, 43, 60);
  @include mixin.liquidSize(margin-bottom, 60, 60);
  display: flex;
  justify-content: center;
  align-items: center;

  margin-left: auto;
  margin-right: auto;
}

.bnrs-blk {
  @include mixin.liquidSize(margin-top, 140, 100);
  @include mixin.liquidSize(margin-bottom, 60, 100);
}
</style>

<template lang="pug">
  .about
    main.user
      Header
      section.kv
      section.content
        .m-content
          .breadcrumb
            breadcrumb(
              h2txt="お祭り・縁日"
              h2link=""
              h3txt=""
              h3link=""
            )

          section
            h2.head
              head2(ja="お祭り・縁日" en="Festival")
            p.desc お祭り、競技大会、行事など屋外イベント用の組立てテントのレンタルサービスをご紹介。
              br
              |商品のお届け・設置まですべてお任せいただけます。


            h3.scene-head
              span.ja テント関連
              span.en Tento

            ul.list
              li.list-item(v-for="product in this.products" :key="product")
                router-link(to="/product/")
                  .img
                    img(:src="product.path")
                  span.name {{ product.name }}
                  .price
                    span.num {{ product.minimumPrice.toLocaleString() }}
                    span.currency 円(税込)


            h3.scene-head
              span.ja ステージ関連
              span.en Stage

            ul.list
              li.list-item(v-for="product in this.products" :key="product")
                router-link(to="/product/")
                  .img
                    img(:src="product.path")
                  span.name {{ product.name }}
                  .price
                    span.num {{ product.minimumPrice.toLocaleString() }}
                    span.currency 円(税込)



      section#scene
        .m-content
          h3.head
            head1(ja="シーン別に探す" en="Scene" type="h")
        scene

      section#flow
        flow
      contact
      Footer
</template>

<script>
// @ is an alias to /src
import Header from "@/components/user/common/header.vue";
import Footer from "@/components/user/common/footer.vue";
import flow from "@/components/user/common/flow.vue";
import contact from "@/components/user/common/contact.vue";
import scene from "@/components/user/common/scene.vue";
import breadcrumb from "@/components/common/breadcrumb.vue";
import head2 from "@/components/common/head-2.vue";
import bnrs from "@/components/user/common/bnrs.vue";
import head1 from "@/components/common/head-1.vue";
export default {
  name: "About",
  metaInfo() {
    return {
      title: "シーン | イベント備品レンタル倉庫 カリトク",
      meta: [
        { name: "description", content: "イベント備品レンタル倉庫 カリトク" },
        { name: "keywords", content: "" },
      ],
    };
  },
  components: {
    Header,
    contact,
    Footer,
    breadcrumb,
    head2,
    bnrs,
    flow,
    scene,
    head1,
  },
  data: function () {
    return {
      products: [
        {
          name: "テント（イベント用）",
          minimumPrice: 18000,
          path: "/img/sample-product.jpg",
        },
        {
          name: "横幕（テント用）",
          minimumPrice: 18000,
          path: "/img/sample-product.jpg",
        },
        {
          name: "テントウエイト 鉄製",
          minimumPrice: 18000,
          path: "/img/sample-product.jpg",
        },
        {
          name: "テントウエイト （タンクタイプ）",
          minimumPrice: 18000,
          path: "/img/sample-product.jpg",
        },
        {
          name: "テント（イベント用）",
          minimumPrice: 18000,
          path: "/img/sample-product.jpg",
        },
        {
          name: "横幕（テント用）",
          minimumPrice: 18000,
          path: "/img/sample-product.jpg",
        },
      ],
    };
  },
  computed: {
    loadImg(path) {
      return require("@/assets/" + path);
    },
  },
};
</script>

<style lang="scss" scoped>
@use "@/css/utility/_colors" as colors;
@use "@/css/utility/_mixin" as mixin;
@use "@/css/utility/_media" as media;
@use "@/css/utility/_font" as font;
@use "@/css/utility/_variables" as variables;
.head {
  @include mixin.liquidSize(margin-bottom, 30, 45);
}

.desc {
  line-height: 2;
  @include mixin.liquidSize(font-size, 15, 26);
  @include mixin.liquidSize(margin-bottom, 70, 60);
}

.scene-head {
  border-bottom: solid 1px #9cced8;
  @include mixin.liquidSize(padding-bottom, 12, 20);
  @include mixin.liquidSize(margin-bottom, 45, 50);
  .ja {
    @include mixin.liquidSize(font-size, 17, 26);
    font-weight: 700; /* bold */
    @include mixin.liquidSize(margin-right, 16, 20);
  }
  .en {
    color: #848484;
    @include mixin.liquidSize(font-size, 15, 24);
  }
}

.list {
  display: flex;
  flex-wrap: wrap;
}

.list-item {
  @include mixin.liquidSize(width, 307, 337);
  @include mixin.liquidSize(margin-bottom, 60, 60);
  @include media.max-screen(media.$breakpoint-sm) {
    &:nth-of-type(odd) {
      margin-right: auto;
    }
  }
  @include media.min-screen(media.$breakpoint-md) {
  }
  &:not(:nth-of-type(4n)) {
    @include mixin.liquidSize(margin-right, 30, 0);
  }
  .img {
    @include mixin.liquidSize(margin-bottom, 10, 14);
  }
  .name {
    color: #000;
    @include mixin.liquidSize(font-size, 17, 26);
    display: block;
    @include mixin.liquidSize(margin-bottom, 3, 6);
  }
  .price {
    color: #1890a7;
    .num {
      @include mixin.liquidSize(font-size, 16, 26);
      @include mixin.liquidSize(margin-right, 3, 6);
    }
    .currency {
      @include mixin.liquidSize(font-size, 13, 20);
    }
  }
}

//-シーン別に探す
#scene {
  @include mixin.liquidSize(padding-top, 120, 120);
  @include mixin.liquidSize(padding-bottom, 60, 50);
}
//取引の流れ
#flow {
  @include mixin.liquidSize(padding-top, 60, 50);
}
</style>

<template lang="pug">
  .cart
    main.user
      Header
      .content

        .m-content
          breadcrumb(
            h2txt="見積確認"
            h2link=""
            h3txt=""
            h3link=""
            type="admin"
          )


          cart(
            @changePeriod="getLeftDays"
            :cartItem="estimate.carts"
            v-model="estimate"
            )



          #form
            .form-item
              label.label.require 必須
              span.head 仮予約：
              .labels
                label
                  span
                  input(type="radio" name="reserve" value="1" @click="reserveState = true" readonly v-model="estimate.reserve")
                  span
                  |する
                label
                  input(type="radio" name="reserve" value="0" @click="reserveState = false" readonly v-model="estimate.reserve")
                  span
                  |しない
            .form-item
              .inner-item
                label.label.require 必須
                span.head 属性：
                .labels
                  label
                    span
                    input(type="radio" name="company" readonly v-model="estimate.attribute" value="1")
                    span
                    |法人
                  label
                    input(type="radio" name="company" readonly v-model="estimate.attribute" value="2")
                    span
                    |個人
              .inner-item.name
                label.label.optional 任意
                span.head 宛名：
                input(type="text" name="name" placeholder="株式会社カリトク 総務部御中" readonly v-model="estimate.address")
                .labels
                  label
                    span
                    input(type="radio" name="title" readonly v-model="estimate.honorific_title" value="1")
                    span
                    |様
                  label
                    input(type="radio" name="title" readonly v-model="estimate.honorific_title" value="2")
                    span
                    |御中

            .form-item
              .inner-item
                label.label.optional(v-if="!reserveState") 任意
                label.label.require(v-if="reserveState") 必須
                span.head 電話：
                input(type="tel" name="tel" placeholder="090-1111-2222" readonly v-model="estimate.tel")
              .inner-item
                label.label.require 必須
                span.head メール：
                input(type="mail" name="mail" placeholder="info@kari-toku.jp" readonly v-model="estimate.mailaddress")

            .form-item.delivery
              .gr-1
                label.label.require 必須
                span.head 受け渡し方法：
              .gr-2
                .gr-2__item
                  span.head （開始時）
                  .labels
                    label
                      span
                      input(type="radio" name="deliveryStart" readonly v-model="estimate.pick_up" value="1")
                      span
                      |引き取り
                    label
                      input(type="radio" name="deliveryStart" readonly v-model="estimate.pick_up" value="2")
                      span
                      |配送・郵送を希望する
                .gr-2__item
                  span.head （返却時）
                  .labels
                    label
                      span
                      input(type="radio" name="deliveryEnd" readonly v-model="estimate.give_back" value="1")
                      span
                      |来社
                    label
                      input(type="radio" name="deliveryEnd" readonly v-model="estimate.give_back" value="2")
                      span
                      |配送・郵送を希望する

            .form-item
              .inner-item.shippingCost-row
                label.label.require 必須
                span.head 送料：
                input(type="text" name="shippingCost" readonly v-model="estimate.send_price")
                |円

            .form-item
              .inner-item.supportFee-row
                label.label.require 必須
                span.head 組み立てサポート：
                input(type="text" name="supportFee" readonly v-model="estimate.support_price")
                |円

            .form-item(v-for="item, index in estimate.addFields" :key="item.name")
              span.additional-head(v-if="index < 1") 追加項目
              .inner-item.additionalItem-row
                label.label.optional 任意
                input(type="text" name="additionalItem" readonly v-model="estimate.addFields[index].name")
                input(type="text" name="additionalItemFee" readonly v-model="estimate.addFields[index].price")
                |円



</template>

<script>
// @ is an alias to /src
import Header from "@/components/admin/common/header.vue";
import breadcrumb from "@/components/common/breadcrumb.vue";
import cart from "@/components/user/cart/cart.vue";
import ApiClient from "@/lib/http.js";
export default {
  name: "Cart",
  metaInfo() {
    return {
      title: "見積確認 | イベント備品レンタル倉庫 カリトク",
      meta: [
        { name: "description", content: "イベント備品レンタル倉庫 カリトク" },
        { name: "keywords", content: "" },
      ],
    };
  },
  components: {
    Header,
    breadcrumb,
    cart,
  },
  data: function () {
    return {
      reserveState: false,
      leftDays: 0,
      //見積りデータ
      estimate: {
        id: "",
        cart: [],
        start_date: "",
        end_date: "",
        reserve: "",
        attribute: "",
        address: "",
        honorific_title: "",
        tel: "",
        mailaddress: "",
        pick_up: "",
        give_back: "",
        tax: 0,
        subtotal: 0,
        fix_price: 0,
        send_price: 0,
        support_price: 0,
        addFields: [],
      },
    };
  },
  async created() {
    this.getEstimate();
  },
  methods: {
    //cart からのイベント
    getLeftDays: function (v) {
      this.leftDays = v;
    },
    async getEstimate() {
      if (this.$route.params.id > 0) {
        const api = new ApiClient();
        let data = await api.get(
          "/admin/estimate/detail/" + this.$route.params.id
        );
        this.estimate = data.detail;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@use "@/css/utility/_colors" as colors;
@use "@/css/utility/_mixin" as mixin;
@use "@/css/utility/_media" as media;
@use "@/css/utility/_font" as font;
@use "@/css/utility/_variables" as variables;

.content {
  background: #fafafa;
  @include mixin.liquidSize(padding, 55, 40);
}

//フォーム
#form {
  @include mixin.liquidSize(width, 954, 700);
  background: #fff;
  box-sizing: border-box;
  @include mixin.liquidSize(padding, 55, 40);
  @include mixin.liquidSize(margin-bottom, 110, 100);
}

.form-item {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border-bottom: dotted 1px #c7c7c7;
  @include mixin.liquidSize(padding-top, 25, 0);
  @include mixin.liquidSize(padding-bottom, 25, 0);
  @include mixin.liquidSize(min-height, auto, 90);

  .inner-item {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    box-sizing: border-box;
    @include mixin.liquidSize(padding-top, 0, 20);
    @include mixin.liquidSize(padding-bottom, 0, 20);
    @include mixin.liquidSize(min-height, auto, 90);
    @include media.max-screen(media.$breakpoint-sm) {
      width: 100%;
      &:not(:last-of-type) {
        border-bottom: dotted 1px #c7c7c7;
      }
    }
    //例外 宛名
    &.name {
      .labels {
        @include mixin.liquidSize(margin-left, 0, 200);
      }
    }
    //例外 送料、組み立てサポート
    &.shippingCost-row,
    &.supportFee-row {
      @include mixin.liquidSize(font-size, 14, 24);
      .head {
        @include mixin.liquidSize(width, 170, 270);
      }
    }
    //例外 追加項目
    .additionalItem-row {
      @include mixin.liquidSize(font-size, 14, 24);
    }
  }

  .label {
    color: #fff;
    @include mixin.liquidSize(font-size, 13, 20);
    @include mixin.liquidSize(width, 41, 60);
    @include mixin.liquidSize(height, 20, 32);
    @include mixin.liquidSize(margin-right, 8, 10);
    @include mixin.liquidSize(margin-bottom, 0, 0);
    @include mixin.liquidSize(border-radius, 4, 8);
    white-space: nowrap;
    box-sizing: border-box;
    @include mixin.liquidSize(padding-left, 7, 10);
    @include mixin.liquidSize(padding-right, 6, 8);
    @include mixin.liquidSize(padding-top, 0, 1);
    @include mixin.liquidSize(padding-bottom, 1, 0);
    line-height: 1;
  }
  .require {
    background: #e57e7e;
  }
  .optional {
    background: #a5a5a5;
  }
  .head {
    @include mixin.liquidSize(font-size, 17, 26);
    @include mixin.liquidSize(margin-right, 10, 0);
    @include mixin.liquidSize(width, auto, 120);
    line-height: 1;
  }
  //例外 受渡方法
  &.delivery {
    @include mixin.liquidSize(padding-top, 40, 35);
    @include mixin.liquidSize(padding-bottom, 10, 15);
    .gr-1 {
      display: flex;
      align-items: center;
      @include mixin.liquidSize(margin-bottom, auto, 40);
    }
    .head {
      @include mixin.liquidSize(width, auto, auto);
    }
    .gr-2 {
      .gr-2__item {
        display: flex;
        align-items: center;
        @include mixin.liquidSize(width, auto, auto);
        &:not(last-of-type) {
          @include mixin.liquidSize(margin-bottom, 25, 30);
        }
        label {
          @include mixin.liquidSize(margin-right, 20, 20);
        }
        .head {
          @include mixin.liquidSize(margin-right, 10, 22);
        }
      }
    }
  }
}

.labels {
  display: flex;
  align-items: center;
}
label {
  display: flex;
  align-items: center;
  @include mixin.liquidSize(font-size, 14, 24);
  @include mixin.liquidSize(margin-right, 14, 62);
  cursor: pointer;
  position: relative;
}

input {
  + span {
    border: solid 1px #adadad;
    @include mixin.liquidSize(width, 20, 26);
    @include mixin.liquidSize(height, 20, 26);
    display: inline-block;
    border-radius: 50%;
    position: relative;
    @include mixin.liquidSize(margin-right, 12, 12);
    box-sizing: border-box;
  }
  &:checked {
    + span {
      &:after {
        content: "";
        @include mixin.liquidSize(width, 12, 16);
        @include mixin.liquidSize(height, 12, 16);
        display: inline-block;
        border-radius: 50%;
        background: #1890a7;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
  &[type="text"],
  &[type="mail"],
  &[type="tel"] {
    @include mixin.liquidSize(font-size, 15, 22);
    background: #f5f6f8;
    @include mixin.liquidSize(height, 45, 62);
    box-sizing: border-box;
    @include mixin.liquidSize(padding-left, 20, 20);
    @include mixin.liquidSize(padding-right, 20, 20);
    @include mixin.liquidSize(border-radius, 6, 6);
    &[readonly="readonly"] {
      background: none;
      border-bottom: dotted 1px #707070;
      @include mixin.liquidSize(border-radius, 0, 0);
      pointer-events: none;
    }
  }
  &[name="name"] {
    @include mixin.liquidSize(width, 325, 430);
    @include mixin.liquidSize(margin-right, 15, 0);
    @include mixin.liquidSize(margin-bottom, 0, 20);
  }
  &[name="tel"] {
    @include mixin.liquidSize(width, 260, 430);
    @include mixin.liquidSize(margin-right, 38, 0);
  }
  &[name="mail"] {
    @include mixin.liquidSize(width, 309, 430);
  }
  &[name="shippingCost"],
  &[name="supportFee"] {
    @include mixin.liquidSize(font-size, 17, 22);
    @include mixin.liquidSize(width, 131, 200);
    text-align: right;
    @include mixin.liquidSize(margin-right, 10, 10);
  }
  &[name="additionalItem"] {
    @include mixin.liquidSize(width, 144, 200);
    @include mixin.liquidSize(margin-right, 25, 30);
    @include mixin.liquidSize(font-size, 17, 22);
  }
  &[name="additionalItemFee"] {
    @include mixin.liquidSize(width, 131, 200);
    text-align: right;
    @include mixin.liquidSize(margin-right, 10, 10);
    @include mixin.liquidSize(font-size, 17, 22);
  }
}

.notes {
  @include mixin.liquidSize(font-size, 14, 20);
  .head {
    @include mixin.liquidSize(font-size, 15, 20);
    @include mixin.liquidSize(margin-right, 0, 0);
  }
  li {
    &:not(:last-of-type) {
      @include mixin.liquidSize(margin-bottom, 15, 30);
    }
  }
  a {
    color: #1890a7;
    border-bottom: dotted 1px #1890a7;
  }
  .icon {
    @include mixin.liquidSize(width, 18, 29);
    @include mixin.liquidSize(height, 20, 32);
    @include mixin.liquidSize(margin-left, 6, 12);
    display: inline-block;
    img {
      width: 100%;
    }
  }
}

.btn {
  background: #1890a7;
  color: #fff;
  @include mixin.liquidSize(width, 280, 620);
  @include mixin.liquidSize(height, 55, 86);
  display: flex;
  align-items: center;
  cursor: pointer;
  box-sizing: border-box;
  position: relative;
  @include mixin.liquidSize(padding-left, 55, 86);
  @include mixin.liquidSize(margin-top, 40, 60);
  @include media.max-screen(media.$breakpoint-sm) {
    margin-left: auto;
    margin-right: auto;
  }
  @include media.min-screen(media.$breakpoint-md) {
    margin-left: auto;
    &:hover {
      .icon {
        width: 100%;
      }
    }
  }
  .icon {
    @include mixin.liquidSize(width, 55, 86);
    @include mixin.liquidSize(height, 55, 86);
    background: #12869b;
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    transition: all 0.4s ease-in-out;
    @include mixin.liquidSize(padding-left, 16, 24);
    box-sizing: border-box;
    img {
      @include mixin.liquidSize(width, 18, 34);
    }
  }
  .txt {
    @include mixin.liquidSize(font-size, 14, 26);
    margin: 0 auto;
    letter-spacing: 0.035em;
    position: relative;
    z-index: 2;
  }
}

.additional-head {
  width: 100%;
  display: block;
  @include mixin.liquidSize(font-size, 18, 26);
  font-weight: 600; /* semibold */
  @include mixin.liquidSize(padding-top, 0, 30);
  @include mixin.liquidSize(margin-bottom, 30, 30);
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
  transition: all 0.2s ease-in-out;
}

.fade-enter-to,
.fade-leave {
  opacity: 1;
  transition: all 0.2s ease-in-out;
}
</style>

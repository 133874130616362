<!-- 日英 横並び -->
<template lang="pug">
  .cart
    .cart-ttl.m-md
      span.ja {{ ja}}
      span.en {{ en }}
    .product-ttl
      span.ja 詳細
      span.en detail

    cartItem(
      @changeTotal="total"
      :items="cartItem"
      )

    .cart-support
      p.support-txt この商品の組み立てサポートについて、別途見積もりを希望する
      .labels
        label
          span
          input(type="radio" name="support" value="true" @change="changeSupport")
          span
          |する
        label
          input(type="radio" name="support" value="false" @change="changeSupport")
          span
          |しない
      p.note ※
        span.bold 組み立てサポート
        |とは、組み立て・解体作業を弊社でサポートするサービスです。
    .subtotal
      span.num {{subtotal.toLocaleString()}}
      span.currency 円(税込)
    .btn-add2cart(@click="add2cart")
      span.icon
        img(src="@/assets/icon-cart-2.svg")
      span.txt 見積りカートに追加

</template>

<script>
import cartItem from "@/components/user/product/cartItem.vue";
export default {
  name: "cart",
  components: {
    cartItem,
  },
  data: function () {
    return {
      subtotal: 0,
      items: [],
      support: "",
    };
  },
  props: {
    ja: String,
    en: String,
    desc: String,
    type: String,
    cartItem: [],
  },
  methods: {
    total(...args) {
      this.subtotal = args[0];
      this.items = args[1];
    },
    changeSupport(e) {
      this.support = e.target.value;
    },
    add2cart() {
      this.$emit("add2cart", this.items, this.support);
    },
  },
};
</script>

<style scoped lang="scss">
@use "@/css/utility/_colors" as colors;
@use "@/css/utility/_mixin" as mixin;
@use "@/css/utility/_media" as media;
@use "@/css/utility/_font" as font;
@use "@/css/utility/_variables" as variables;
//-カート
.cart {
  @include mixin.liquidSize(width, 670, 750);
  @include mixin.liquidSize(padding-left, 50, 25);
  @include mixin.liquidSize(padding-right, 50, 25);
  @include mixin.liquidSize(margin-bottom, 0, 60);
  box-sizing: border-box;
  @include media.min-screen(media.$breakpoint-md) {
    position: absolute;
    top: 0;
    left: calc(50% + 40px);
  }
}

.cart-ttl {
  @include mixin.liquidSize(margin-bottom, 80, 60);
  .ja {
    @include mixin.liquidSize(font-size, 25, 40);
    font-weight: 700; /* bold */
    display: block;
  }
  .en {
    @include mixin.liquidSize(font-size, 17, 32);
    color: #848484;
  }
}

.cart-support {
  .labels {
    display: flex;
    align-items: center;
    border-bottom: dotted 1px #dedede;
    @include mixin.liquidSize(padding-bottom, 20, 50);
  }
  label {
    display: flex;
    align-items: center;
    @include mixin.liquidSize(font-size, 14, 28);
    @include mixin.liquidSize(margin-right, 31, 62);
    cursor: pointer;
  }
  input {
    + span {
      border: solid 1px #adadad;
      @include mixin.liquidSize(width, 20, 40);
      @include mixin.liquidSize(height, 20, 40);
      display: inline-block;
      border-radius: 50%;
      position: relative;
      @include mixin.liquidSize(margin-right, 9, 18);
    }
    &:checked {
      + span {
        &:after {
          content: "";
          @include mixin.liquidSize(width, 12, 24);
          @include mixin.liquidSize(height, 12, 24);
          display: inline-block;
          border-radius: 50%;
          background: #1890a7;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }
  .note {
    @include mixin.liquidSize(font-size, 13, 20);
    @include mixin.liquidSize(margin-top, 15, 25);
  }
}

.support-txt {
  @include mixin.liquidSize(font-size, 15, 30);
  @include mixin.liquidSize(margin-bottom, 10, 30);
  &:before {
    content: "必須";
    background: #e57e7e;
    color: #fff;
    @include mixin.liquidSize(font-size, 13, 26);
    @include mixin.liquidSize(width, 41, 80);
    @include mixin.liquidSize(height, 20, 40);
    @include mixin.liquidSize(margin-right, 12, 0);
    @include mixin.liquidSize(margin-bottom, 0, 20);
    @include mixin.liquidSize(border-radius, 4, 8);

    box-sizing: border-box;
    @include mixin.liquidSize(padding-left, 6, 12);
    @include mixin.liquidSize(padding-right, 6, 12);
    @include media.max-screen(media.$breakpoint-sm) {
      display: block;
    }
    @include media.min-screen(media.$breakpoint-md) {
      display: inline-block;
    }
  }
}

.product-ttl {
  border-bottom: solid 1px #b2b1b1;
  @include mixin.lhFix(1);
  line-height: 1;
  @include mixin.liquidSize(padding-left, 0, 10);
  @include mixin.liquidSize(padding-right, 0, 10);
  @include mixin.liquidSize(padding-bottom, 15, 30);
  @include mixin.liquidSize(margin-bottom, 20, 40);
  display: flex;
  align-items: center;
  .ja {
    color: #1890a7;
    font-weight: 700; /* bold */
    @include mixin.liquidSize(font-size, 17, 32);
    @include mixin.liquidSize(margin-right, 15, 30);
  }
  .en {
    color: #848484;
    font-weight: 500; /* medium */
    @include mixin.liquidSize(font-size, 14, 26);
  }
}

.subtotal {
  color: #1890a7;
  @include mixin.liquidSize(margin-top, 50, 50);
  @include mixin.liquidSize(margin-bottom, 25, 90);
  text-align: right;
  .num {
    @include mixin.liquidSize(font-size, 35, 38);
    @include mixin.liquidSize(margin-right, 10, 19);
  }
  .currency {
    @include mixin.liquidSize(font-size, 17, 24);
  }
}

.btn-add2cart {
  background: #1890a7;
  color: #fff;
  @include mixin.liquidSize(width, 280, 660);
  @include mixin.liquidSize(height, 55, 86);
  @include mixin.liquidSize(margin-bottom, 30, 130);
  display: flex;
  align-items: center;
  cursor: pointer;
  @include media.max-screen(media.$breakpoint-sm) {
    margin-left: auto;
    margin-right: auto;
  }
  @include media.min-screen(media.$breakpoint-md) {
    margin-left: auto;
  }
  .icon {
    @include mixin.liquidSize(width, 55, 86);
    @include mixin.liquidSize(height, 55, 86);
    background: #12869b;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .txt {
    @include mixin.liquidSize(font-size, 14, 26);
    margin: 0 auto;
    letter-spacing: 0.035em;
  }
}
</style>
